import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes, Navigate, useNavigate } from "react-router-dom";
import { getRegistryCredentials } from './utils/registryUtils';
import { cleanupModalArtifacts } from "./utils/modalCleanup";
import { initializeApi } from "./services/api";
import { Device } from '@capacitor/device';
import useNetworkStatus from "./components/hooks/useNetworkStatus";
import MainPage from "./pages/Main/MainPage"; 
import MobileLogin from "./components/auth/MobileLogin";
import Signup from "./components/auth/Signup";
import MobileHomepage from "./components/sections/MobileHomepage";
import FindRegistryPage from "./pages/Main/FindRegistryPage";
import ForgotPasswordPage from "./pages/Main/ForgotPasswordPage";
import AboutUsPage from "./pages/Main/AboutUsPage";
import PrivacyPolicyPage from "./pages/Main/PrivacyPolicyPage";
import TermsAndConditionPage from "./pages/Main/TermsAndConditionPage";
import AdvertiseWithUsPage from "./pages/Main/AdvertiseWithUsPage";
import FeedbackPage from "./pages/Main/FeedbackPage";
import OnboardingProcessPage from "./pages/Dashboard/OnboardingProcessPage";
import SelectRegistryPage from "./pages/Dashboard/SelectRegistryPage";
import DashboardPage from "./pages/Dashboard/DashboardPage";
import AddGiftsPage from "./pages/Dashboard/AddGiftsPage";
import ScriptBlockedPage from "./pages/Main/ScriptBlockedPage";
import ShopPage from "./pages/Main/ShopPage";
import ShowAllPage from "./pages/Main/ShowAllPage";
import ProductPage from "./pages/Main/ProductPage";
import PopularGiftsPage from "./pages/Dashboard/PopularGiftsPage";
import BabyChecklistPage from "./pages/Dashboard/BabyChecklistPage";
import RegistrySettingsPage from "./pages/Dashboard/RegistrySettingsPage";
import NotificationsPage from "./pages/Dashboard/NotifiactionsPage";
import GuestbookPage from "./pages/Dashboard/GuestbookPage";
import VisitorSetupPage from "./pages/Dashboard/VisitorSetupPage";
import ViewAsVisitorPage from "./pages/Dashboard/ViewAsVisitorPage";
import ViewProductPage from "./pages/Dashboard/ViewProductPage";
import PurchaseProcessPage from "./pages/Dashboard/PurchaseProcessPage";
import AddGiftModalPage from "./pages/AddGift/AddGiftModalPage";
import ProtectedRoute from "./components/auth/ProtectedRoute";
import AppUrlListener from "./components/listener/AppUrlListener";
import NoConnectionModal from "./components/dashboard/modal/NoConnectionModal";
import AppInstallBanner from "./components/other/AppInstallBanner";
import BlogPostFirst from "./pages/Blogs/BlogPostFirst";
import BlogPostSecond from "./pages/Blogs/BlogPostSecond";
import PlatformRedirect from "./utils/platformRedirect";
import NotFoundPage from "./pages/Main/NotFoundPage";
import "./App.css";

const RedirectToDashboard = ({ element }) => {
  const { accessToken } = getRegistryCredentials();

  if (accessToken) {
    return <Navigate to="/dashboard" replace />;
  }
  return element;
};

function App() {
  const { accessToken, fromMobileApp } = getRegistryCredentials();
  const [isBannerVisible, setIsBannerVisible] = useState(true);
  const [showModal, setShowModal] = useState(false);
  useNetworkStatus(setShowModal);

  return (
    <BrowserRouter>
      <AppUrlListener />
      <AppInstallBanner isVisible={isBannerVisible} onClose={() => setIsBannerVisible(false)} />
      <div>
        <RoutesWrapper accessToken={accessToken} fromMobileApp={fromMobileApp} />
      </div>
      {showModal && <NoConnectionModal setShowModal={setShowModal} />}
    </BrowserRouter>
  );
}

function RoutesWrapper({ accessToken, fromMobileApp }) {
  const navigate = useNavigate();

  useEffect(() => {
    const checkPlatform = async () => {
      const isChecked = localStorage.getItem('isPlatformChecked');
      if (!isChecked) {
        const info = await Device.getInfo();
        if (info.platform === 'ios' || info.platform === 'android') {
          navigate('/m-homepage', { replace: true });
        }
        localStorage.setItem('isPlatformChecked', 'true');
      }
    };

    checkPlatform();
    initializeApi(navigate);

    if (typeof window !== "undefined" && window.scrollTo) {
      window.scrollTo(0, 0);
    }

    return () => {
      cleanupModalArtifacts();
    };
  }, [navigate]);

  return (
    <Routes>
      {fromMobileApp ? (
        <Route path="/" element={accessToken ? <Navigate to="/dashboard" /> : <MainPage />} />
      ) : (
        <Route path="/" element={<MainPage />} />
      )}
      <Route path="/m-login" element={<RedirectToDashboard element={<MobileLogin />} />} />
      <Route path="/signup" element={<RedirectToDashboard element={<Signup />} />} />
      <Route path="/m-homepage" element={<RedirectToDashboard element={<MobileHomepage />} />} />
      <Route path="/forgot-password" element={<ForgotPasswordPage />} />
      <Route path="/aboutus" element={<AboutUsPage />} />
      <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
      <Route path="/terms&conditions" element={<TermsAndConditionPage />} />
      <Route path="/advertise-with-us" element={<AdvertiseWithUsPage />} />
      <Route path="/feedback" element={<FeedbackPage />} />
      <Route path="/search" element={<FindRegistryPage />} />
      <Route path="/add-gifts" element={<AddGiftsPage />} />
      <Route path="/script-blocked" element={<ScriptBlockedPage />} />
      <Route path="/shop" element={<ShopPage />} />
      <Route path="/categories" element={<ShowAllPage />} />
      <Route path="/categories/:category" element={<ProductPage />} />
      <Route path="/baby-checklist" element={<BabyChecklistPage />} />
      <Route path="/giftlist/:publicKey" element={<ViewAsVisitorPage />} />
      <Route path="/view-product" element={<ViewProductPage />} />
      <Route path="/purchase-process" element={<PurchaseProcessPage />} />
      <Route path="/add-gift-modal" element={<AddGiftModalPage />} />
      <Route path="/why-gift-registry-is-better" element={<BlogPostFirst />} />
      <Route path="/why-parents-need-baby-registry" element={<BlogPostSecond />} />
      <Route path="/onboarding-process" element={<ProtectedRoute element={<OnboardingProcessPage />} />} />
      <Route path="/all-registry" element={<ProtectedRoute element={<SelectRegistryPage />} />} />
      <Route path="/dashboard" element={<ProtectedRoute element={<DashboardPage />} />} />
      <Route path="/popular-gifts" element={<ProtectedRoute element={<PopularGiftsPage />} />} />
      <Route path="/visitor-setup" element={<ProtectedRoute element={<VisitorSetupPage />} />} />
      <Route path="/guestbook" element={<ProtectedRoute element={<GuestbookPage />} />} />
      <Route path="/registry-settings" element={<ProtectedRoute element={<RegistrySettingsPage />} />} />
      <Route path="/notifications" element={<ProtectedRoute element={<NotificationsPage />} />} />
      <Route path="/start" element={<PlatformRedirect />} />
      <Route path="/not-found" element={<NotFoundPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default App;