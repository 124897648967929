import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { showToast } from '../../utils/toastUtils';
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import { initializeGoogleAuth } from "../../utils/googleAuthUtils";
import { SignInWithApple } from "@capacitor-community/apple-sign-in";
import { getRegistryCredentials } from "../../utils/registryUtils";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import api from "../../services/api";
import Cookies from 'js-cookie';

const MobileLogin = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(true);
  const [usernameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [termsError, setTermsError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const { fromiOSDevice } = getRegistryCredentials();
  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    initializeGoogleAuth();
  }, []);

  useEffect(() => {
    const savedUsername = sessionStorage.getItem("username");
    const savedPassword = sessionStorage.getItem("password");
    const savedTermsAccepted = sessionStorage.getItem("termsAccepted");
  
    if (savedUsername) setUsername(savedUsername);
  
    if (savedPassword) {
      const decodedPassword = atob(savedPassword);
      setPassword(decodedPassword);
    }
  
    if (savedTermsAccepted !== null) setTermsAccepted(savedTermsAccepted === "true");
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleForgotPassword = () => {
    navigate("/forgot-password");
  };

  const handleUsernameChange = (e) => {
    const usernameValue = e.target.value;
    setUsername(usernameValue);
    setUsernameError("");
    sessionStorage.setItem("username", usernameValue);
    setError("");
  };
  
  const handlePasswordChange = (e) => {
    const passwordValue = e.target.value;
    const encodedPassword = btoa(passwordValue);
    setPassword(passwordValue);
    setPasswordError("");
    sessionStorage.setItem("password", encodedPassword);
    setError("");
  };
  

  const handleTermsChange = (e) => {
    const termsAcceptedValue = e.target.checked;
    setTermsAccepted(termsAcceptedValue);
    sessionStorage.setItem("termsAccepted", termsAcceptedValue.toString());
    setTermsError("");
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    let isUsernameValid = true;
    let isPasswordValid = true;

    if (!navigator.onLine) {
        showToast(<><i className="la la-warning i-icon"></i>&nbsp;You are offline!</>);
        return;
    }

    if (!executeRecaptcha) {
      showToast('reCAPTCHA not ready, please try again');
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!username.trim()) {
        setUsernameError("Email is required. Please enter a valid email.");
        isUsernameValid = false;
    } else if (!emailRegex.test(username)) {
        setUsernameError("Invalid email. Please enter a valid email.");
        isUsernameValid = false;
    } else {
        setUsernameError("");
    }
    
    if (!password.trim()) {
        setPasswordError("Password is required. Please enter a valid password.");
        isPasswordValid = false;
    } else {
        setPasswordError("");
    }

    if (!termsAccepted) {
        setTermsError("You must accept the terms and conditions to continue.");
        return;
    }

    if (isUsernameValid && isPasswordValid) {
      try {
          setLoading(true);
          const recaptchaToken = await executeRecaptcha("mobile_login");
          const response = await api.login(username, password, recaptchaToken);

          if (response.error) {
            setError(response.error);
            return;
          }

          const userInfo = {
              accessToken: response.access_token,
              userId: response.user.id,
              userName: response.user.first_name,
              userEmail: response.user.email,
          };

          Object.entries(userInfo).forEach(([key, value]) => {
              Cookies.set(key, value, { expires: 30, sameSite: 'None', secure: true });
              localStorage.setItem(key, value);
          });

          if (response.registry_created === "true") {
            const registryList = response.user.registry;
            const primaryRegistry = registryList[0];
            let registryInfo;
    
            if (primaryRegistry.process_complete === "true") {
              registryInfo = {
                registryId: primaryRegistry.id,
                registryTypeId: primaryRegistry.registry_type_id,
                registryTypeName: primaryRegistry.registry_type_name,
                registryPublic: primaryRegistry.public,
              };
    
              if (registryList.length > 1) {
                navigate("/all-registry");
              } else if (registryList.length === 1) {
                navigate("/dashboard");
              }
            } else {
              registryInfo = {
                stagingRegistryId: primaryRegistry.id,
                stagingRegistryTypeId: primaryRegistry.registry_type_id,
              };
    
              navigate("/onboarding-process?processStep=2", { state: { initialRegistry: true } });
              showToast("Please complete your registry setup");
            }

            Object.entries(registryInfo).forEach(([key, value]) => {
              Cookies.set(key, value, { expires: 30, sameSite: 'None', secure: true });
              localStorage.setItem(key, value);
            });
    
          } else {
            navigate("/onboarding-process", { state: { initialRegistry: true } });
            showToast("Please complete your registry setup");
          }
          sessionStorage.removeItem("username");
          sessionStorage.removeItem("password");
          sessionStorage.removeItem("termsAccepted");
      } catch (error) {
          setError("An error occurred. Please try again.");
      } finally {
          setLoading(false);
      }
    }
  };

  const googleLogin = async () => {
    try {
      const response = await GoogleAuth.signIn({
        prompt: "select_account",
      });
      handleGoogleLogin(response);
      
    } catch (error) {
      console.error("Error during Google login:", error);
    }
  };

  const handleGoogleLogin = async (response) => {
    try {
        const id_token = response.authentication.accessToken;
        const apiResponse = await api.googleLogin(id_token);
        const { access_token, user } = apiResponse.user.original;
        const userType = apiResponse.user.original.userType;
        const registryCreated = apiResponse.user.original.registry_created === 'true';

        Object.entries({
          accessToken: access_token,
          userId: user.id,
          userName: user.first_name,
          userEmail: user.email,
        }).forEach(([key, value]) => {
          Cookies.set(key, value, { expires: 30, sameSite: 'None', secure: true });
          localStorage.setItem(key, value);
        });

        if (userType === 'new') {
          navigate('/onboarding-process', { state: { initialRegistry: true } });
        } else if (userType === 'old') {
        if (registryCreated) {
          const registryList = user.registry;
          const primaryRegistry = registryList[0];
          const processComplete = primaryRegistry.process_complete === 'true';
          let registryInfo;
  
          if (processComplete) {
            registryInfo = {
              registryId: primaryRegistry.id,
              registryTypeId: primaryRegistry.registry_type_id,
              registryTypeName: primaryRegistry.registry_type_name,
              registryPublic: primaryRegistry.public,
            };
  
            if (registryList.length > 1) {
              navigate("/all-registry");
            } else if (registryList.length === 1) {
              navigate("/dashboard");
            }
          } else {
            registryInfo = {
              stagingRegistryId: primaryRegistry.id,
              stagingRegistryTypeId: primaryRegistry.registry_type_id,
            };
  
            navigate("/onboarding-process?processStep=2", { state: { initialRegistry: true } });
            showToast("Please complete your registry setup");
          }

          Object.entries(registryInfo).forEach(([key, value]) => {
            Cookies.set(key, value, { expires: 30, sameSite: 'None', secure: true });
            localStorage.setItem(key, value);
          });
        } else {
          navigate("/onboarding-process", { state: { initialRegistry: true } });
          showToast("Please Select a registry");
        }
      }
    } catch (error) {
        console.error('Error during Google login:', error);
        showToast("Failed to Google login");
    }
  };

  const appleLogin = async () => {
    let options = {
      clientId: 'in.quickregistry.applesignin',
      redirectURI: 'https://quickregistry-e2113.firebaseapp.com//auth/handler',
      scopes: 'email',
      state: '12345',
    };

    try {
      const appleResponse = await SignInWithApple.authorize(options);

      const identityToken = appleResponse.response.identityToken;
      if (!identityToken) {
        throw new Error("Identity token is missing from the Apple login response.");
      }

      const givenName = appleResponse.response.givenName || "Apple";
      const familyName = appleResponse.response.familyName || "User";

      const apiResponse = await api.appleLogin(identityToken, null, givenName, familyName);
      handleAppleLogin(apiResponse);

    } catch (error) {
      console.error("Error during Apple login: ", error);
    }
  };

  const handleAppleLogin = async (apiResponse) => {
    try {
        const access_token = apiResponse.access_token;
        const user = apiResponse.user;
        const userType = apiResponse.userType;
        const registryCreated = apiResponse.registry_created === 'true';

        Object.entries({
          accessToken: access_token,
          userId: user.id,
          userName: user.first_name,
          userEmail: user.email,
        }).forEach(([key, value]) => {
          Cookies.set(key, value, { expires: 30, sameSite: 'None', secure: true });
          localStorage.setItem(key, value);
        });

        if (userType === 'new') {
          navigate('/onboarding-process', { state: { initialRegistry: true } });
        } else if (userType === 'old') {
        if (registryCreated) {
          const registryList = user.registry;
          const primaryRegistry = registryList[0];
          const processComplete = primaryRegistry.process_complete === 'true';
          let registryInfo;
  
          if (processComplete) {
            registryInfo = {
              registryId: primaryRegistry.id,
              registryTypeId: primaryRegistry.registry_type_id,
              registryTypeName: primaryRegistry.registry_type_name,
              registryPublic: primaryRegistry.public,
            };
  
            if (registryList.length > 1) {
              navigate("/all-registry");
            } else if (registryList.length === 1) {
              navigate("/dashboard");
            }
          } else {
            registryInfo = {
              stagingRegistryId: primaryRegistry.id,
              stagingRegistryTypeId: primaryRegistry.registry_type_id,
            };
  
            navigate("/onboarding-process?processStep=2", { state: { initialRegistry: true } });
            showToast("Please complete your registry setup");
          }

          Object.entries(registryInfo).forEach(([key, value]) => {
            Cookies.set(key, value, { expires: 30, sameSite: 'None', secure: true });
            localStorage.setItem(key, value);
          });
        } else {
          navigate("/onboarding-process", { state: { initialRegistry: true } });
          showToast("Please Select a registry");
        }
      }
    } catch (error) {
      console.error('Error during Apple login:', error);
      showToast("Failed to Apple login");
    }
  };

  return (
    <div className="wrapper animsition">
      <div className="wrapper-inner">
        <main className="main">
          <article>
            <section className="onboarding-process">
              <div className="container">
                <div className="row">
                  <div className="process-box">
                    <div className="col-md-12 text-center">
                    {fromiOSDevice && <div className="spacing-mid"></div>}
                      <div className="spacing-mid"></div>
                      <Link to="/" className="navbar-logo">
                        <img
                          src="assets/images/logo.png"
                          alt="logo"
                          loading="lazy"
                        />
                      </Link>
                      <div className="spacing-mid"></div>
                    </div>
                    <div className="col-md-12 text-center">
                      <h1>Login to your Registry</h1>
                      <div className="spacing-mid"></div>
                    </div>
                    <div className="col-md-12 mt-4">
                      <form className="login-form" noValidate>
                        {error && (
                            <p className="validation-login-main text-danger text-center">{error}</p>
                        )}
                        <div className="row">
                          <div className="col-md-12 mb-2">
                            <div className="user-box">
                              <input
                                type="text"
                                name="email"
                                value={username}
                                onChange={handleUsernameChange}
                                onKeyPress={(e) => e.key === 'Enter' && handleLogin(e)}
                                className={`form-control ${usernameError ? "is-invalid" : ""}`}
                                autoComplete="offAuto"
                                required
                              />
                              <p className="validation invalid-feedback"> {usernameError} </p>
                              <label>Email</label>
                            </div>
                          </div>
                          <div className="col-md-12 mb-2">
                            <div className="user-box">
                              <input
                                type={showPassword ? "text" : "password"}
                                name="password"
                                value={password}
                                onChange={handlePasswordChange}
                                onKeyPress={(e) => e.key === 'Enter' && handleLogin(e)}
                                className={`mb-2 form-control ${passwordError ? "is-invalid" : ""}`}
                                maxLength={20}
                                required
                              />
                              <label>Password</label>
                              <Link
                                className="password-toggle-icon position-absolute end-0 bottom-50 me-4"
                                onClick={togglePasswordVisibility}
                              >
                                {showPassword ? (
                                  <i className="las la-eye-slash fs-4"></i>
                                ) : (
                                  <i className="las la-eye fs-4"></i>
                                )}
                              </Link>
                              <p className="validation-signup invalid-feedback"> {passwordError} </p>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="user-box float-end">
                                <Link data-bs-dismiss="modal" onClick={handleForgotPassword}>
                                Forgot Password?
                                </Link>
                            </div>
                          </div>
                          <div className="col-md-12 mt-3">
                            <div className="user-box">
                              <div className="checkbox checkbox-container checkbox-size">
                                <input
                                  type="checkbox"
                                  id="termsCheckbox"
                                  name="termsAccepted"
                                  checked={termsAccepted}
                                  onChange={handleTermsChange}
                                  required
                                />
                                <label
                                  className="checkbox-text mobile-checkbox-text"
                                >
                                  I accept the website's&nbsp;
                                  <Link to="/terms&conditions" onClick={() => window.scrollTo(0, 0)}>Terms & Conditions</Link> and&nbsp;
                                  <Link to="/privacy-policy" onClick={() => window.scrollTo(0, 0)}>Privacy Policy</Link>
                                </label>
                              </div>
                              <p className="validation-signup text-danger">{termsError}</p>
                            </div>
                          </div>
                          <div className="col-md-12 mt-4">
                            <div className="user-box">
                            <button
                              className="btn btn-primary form-btn main-btn"
                              onClick={handleLogin}
                              disabled={loading}
                            >
                              {loading ? (
                                <>
                                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> &nbsp;Logging in...
                                </>
                              ) : (
                                "Login"
                              )}
                            </button>
                            </div>
                            <div className="user-box or text-center">
                              <p>or</p>
                            </div>
                            <div className="user-box text-center">
                              <div className="social-btn">
                                <Link to="#" className="social-login-btn" onClick={() => googleLogin()}>
                                  <img src="/assets/images/icon/google-icon.svg" alt="Google icon" className="me-2" style={{ width: '22px', height: '22px' }} />
                                  Sign in with Google
                                </Link>
                              </div>
                              {fromiOSDevice && (
                                <div className="social-btn apple-social-btn">
                                  <Link to="#" className="social-login-btn" onClick={() => appleLogin()}>
                                    <img src="/assets/images/icon/apple-icon.svg" alt="Apple icon" className="me-2 apple-icon" style={{ width: '22px', height: '22px' }} />
                                    Sign in with Apple
                                  </Link>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      <button type="submit" className="visually-hidden">Submit</button>
                      </form>
                      <div className="spacing"></div>
                      <p className="recaptcha-disclaimer mt-2">
                        This site is protected by reCAPTCHA and the Google
                      </p>
                    </div>
                    <div className="col-md-12">
                      <div className={`back-btn-text ${fromiOSDevice && 'ios-area'}`}>
                        <p>
                          <Link className="back-button" onClick={() => navigate(-1)}>
                            <i className="la la-angle-left"></i> Back
                          </Link>
                        </p>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className={`login-text ${fromiOSDevice && 'ios-area'}`}>
                        <p>
                          New to Quick Registries?&nbsp;
                          <Link
                            to="/signup"
                            className="login-already"
                          >
                            SignUp
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row"></div>
              </div>
            </section>
          </article>
        </main>
      </div>
    </div>
  );
};

export default MobileLogin;