/* global $ */ /* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useCallback } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { showToast } from "../../utils/toastUtils";
import { useMediaQuery } from '@mui/material';
import LoginModal from "../../components/auth/LoginModal";
import { indianStates } from "../../utils/indianStates";
import { InfoIcon, HtmlTooltip } from '../../utils/tooltipUtils';
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import { questionsByRegistryType } from "../../utils/questionsUtils";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { getRegistryCredentials, clearRegistryCredentials } from '../../utils/registryUtils';
import Cropper from 'react-easy-crop';
import 'react-easy-crop/react-easy-crop.css';
import api from "../../services/api";
import Cookies from 'js-cookie';

const OnboardingProcessPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isPortrait = useMediaQuery('(max-width:575px)');
  const [loading, setLoading] = useState(false);
  const [storedUserName, setStoredUserName] = useState("");
  const [partnerFirstName, setPartnerFirstName] = useState("");
  const [partnerLastName, setPartnerLastName] = useState("");
  const [giftListName, setGiftListName] = useState("");
  const [selectedMyRole, setSelectedMyRole] = useState("");
  const [selectedPartnerRole, setSelectedPartnerRole] = useState("");
  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");
  const [year, setYear] = useState("");
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [city, setCity] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [state, setState] = useState('');
  const [country] = useState('India');
  const [phoneNo, setPhoneNo] = useState('');
  const [croppedImage, setCroppedImage] = useState(null);
  const [showVisitorPassword, setShowVisitorPassword] = useState(false);
  const [enableProfilePicture, setEnableProfilePicture] = useState(true);
  const [isVisitorPasswordValid, setIsVisitorPasswordValid] = useState(true);
  const [allowFoundOnQuickRegistry, setAllowFoundOnQuickRegistry] = useState(true);
  const [visitorPasswordCheckbox, setVisitorPasswordCheckbox] = useState(true);
  const [visitorPassword, setVisitorPassword] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [activeButton, setActiveButton] = useState('extension');
  const query = new URLSearchParams(location.search);
  const startStep = parseInt(query.get("processStep")) || 1;
  const [currentStep, setCurrentStep] = useState(startStep);
  const [registryTypeId, setRegistryTypeId] = useState(() => {
    return Cookies.get("stagingRegistryTypeId") || localStorage.getItem("stagingRegistryTypeId") || "1";
  });
  const [initialRegistry] = useState(() => { return location.state?.initialRegistry || false; });
  const { accessToken, userId, stagingRegistryId, userName, fromMobileApp, fromiOSDevice } = getRegistryCredentials();
  const registryTypeLabels = { "1": "Baby Registry", "2": "Wedding Registry", "3": "Giftlist" };
  const registryTypeName = registryTypeLabels[registryTypeId] || "Registry";
  
  useEffect(() => {
    let currentStepLocal = currentStep;
    var updateProgressBar;

    $('#multi-step-form').find('.step').slice(1).hide();

    $(".next-step").click(function () {
      if (currentStepLocal === 6 && fromMobileApp) {
        return;
      }

      if (currentStepLocal < 7) {
        $(".step-" + currentStepLocal).addClass("animate__animated animate__fadeOutLeft");
        currentStepLocal++;
        setTimeout(function () {
          $(".step").removeClass("animate__animated animate__fadeOutLeft").hide();
          $(".step-" + currentStepLocal).show().addClass("animate__animated animate__fadeInRight");
          setCurrentStep(currentStepLocal);
          updateProgressBar();
        }, 500);
      }
    });

    $(".prev-step").click(function () {
      if (currentStepLocal > 1) {
        $(".step-" + currentStepLocal).addClass("animate__animated animate__fadeOutRight");
        currentStepLocal--;
        setTimeout(function () {
          $(".step").removeClass("animate__animated animate__fadeOutRight").hide();
          $(".step-" + currentStepLocal).show().addClass("animate__animated animate__fadeInLeft");
          setCurrentStep(currentStepLocal);
          updateProgressBar();
        }, 500);
      }
    });

    updateProgressBar = function () {
      var progressPercentage = ((currentStepLocal - 1) / 7) * 100;
      $(".progress-bar").css("width", progressPercentage + "%");
    }

    $(".step").hide();
    $(".step-" + currentStepLocal).show();

  }, [fromMobileApp, currentStep]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };
    
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const handleLogOutClick = () => {
      document.querySelector("ul.overlay").classList.toggle("active-overlay");
    };

    const handleOutsideClick = (event) => {
      const overlay = document.querySelector("ul.overlay");
      const logOutButton = document.querySelector("button.log-out");
      if (overlay && !overlay.contains(event.target) && logOutButton && !logOutButton.contains(event.target)) {
        overlay.classList.remove("active-overlay");
      }
    };

    const logOutButton = document.querySelector("button.log-out");
    if (logOutButton) {
      logOutButton.addEventListener("click", handleLogOutClick);
    }

    document.addEventListener("click", handleOutsideClick);

    return () => {
      if (logOutButton) {
        logOutButton.removeEventListener("click", handleLogOutClick);
      }
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (userName) {
      setStoredUserName(userName);
    }
    // eslint-disable-next-line
  }, []);

  const fetchRegistryAddress = async () => {
    try {
      const response = await api.getRegistryAddress(accessToken, parseInt(userId));
      const { address, mobile_number } = response;
      
      if (address && Object.keys(address).length > 0) {
        const { first_name, last_name, address_1, address_2, city, state, zip_code } = address;

        setFirstName(first_name || '');
        setLastName(last_name || '');
        setAddress1(address_1 || '');
        setAddress2(address_2 || '');
        setCity(city || '');
        setState(state || '');
        setZipCode(zip_code || '');
        setPhoneNo(mobile_number || '');
      }
    } catch (error) {
        console.error("Error fetching address:", error.message);
    }
  };

  useEffect(() => {
    if (currentStep === 2) {
        fetchRegistryAddress();
    }
    // eslint-disable-next-line
  }, [currentStep]);

  const handleRegistrySelection = async (selectedTypeId, name) => {
    try {
      setRegistryTypeId(selectedTypeId);
      const registryResponse = await api.createRegistryId(accessToken, parseInt(userId), selectedTypeId, name);
      const stagingRegistryId = registryResponse.registry_id;
      Cookies.set('stagingRegistryId', stagingRegistryId, { expires: 30, sameSite: 'None', secure: true });
      localStorage.setItem('stagingRegistryId', stagingRegistryId);
    } catch (error) {
      console.error("Error creating registry ID:", error.message);
      showToast('Failed to select registry. Please try again', "error");
    }
  };

  const handleFirstNameChange = (e) => {
    setPartnerFirstName(e.target.value.trim());
  };

  const handleLastNameChange = (e) => {
    setPartnerLastName(e.target.value.trim());
  };

  const handleGiftListNameChange = (e) => {
    setGiftListName(e.target.value);
  };

  const handleSkipDetails = () => {
    setGiftListName("");
    setPartnerFirstName("");
    setPartnerLastName("");
    setSelectedPartnerRole("");
  };

  const handleMonthChange = (event) => {
    setMonth(event.target.value);
  };

  const handleDayChange = (event) => {
    const inputValue = event.target.value;
    if (inputValue === '' || (/^\d+$/.test(inputValue) && inputValue >= 1 && inputValue <= 31)) {
      setDay(inputValue);
    }
  };
  
  const handleYearChange = (event) => {
    const inputValue = event.target.value;
    if (inputValue === '' || (/^\d+$/.test(inputValue) && inputValue.length <= 4)) {
      setYear(inputValue);
    }
  };

  const validatePartnerDetails = () => {
    return partnerFirstName.trim() !== '' && partnerLastName.trim() !== '' && selectedPartnerRole !== '';
  };  

  const validateGiftlistDetails = () => {
    return giftListName && giftListName.trim() !== "";
  };

  const validateEventDate = () => {
    return month !== '' && day !== '' && year !== '' && year.length === 4;
  };

  const validateForm = () => {
    return (
      firstName.trim() !== '' &&
      lastName.trim() !== '' &&
      address1.trim() !== '' &&
      city.trim() !== '' &&
      zipCode.trim().length === 6 &&
      state.trim() !== '' &&
      phoneNo.trim().length === 10
    );
  };

  const validatePassword = () => {
    return !visitorPasswordCheckbox || visitorPassword.trim() !== '';
  };

  const validateProfileImage = () => {
    return !enableProfilePicture || !!croppedImage;
  };

  const handleUpdateGiftlist = async () => {
    try {
      await api.updateRegistryDetails(accessToken, stagingRegistryId, { name: giftListName });
    } catch (error) {
      console.error("Failed to save Gift List name:", error);
      showToast("Failed to save Giftlist name", "error");
    }
  };
  
  const handleSaveDetailsAnswers = async () => {
    try {
      const partnerName = `${partnerFirstName} ${partnerLastName}`.trim();
      const formattedDate = `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      const registryQuestionMapping = {
        1: {
          myRole: "1",
          partnerName: "2",
          arrivalDate: "3"
        },
        2: {
          myRole: "4",
          partnerName: "5",
          eventDate: "6"
        },
        3: {
          CelebrationDate: "7",
          partnerName: "8"
        }
      };
    
      const questionIds = registryQuestionMapping[registryTypeId.toString()];
      let userDetails = [];

      if (registryTypeId.toString() === "3") {
        userDetails = [
          {
            "registry_question_id": questionIds.CelebrationDate,
            "question_answer": formattedDate,
            "remarks": "Celebration Date"
          },
          {
            "registry_question_id": questionIds.partnerName,
            "question_answer": partnerName,
            "remarks": ""
          }
        ];
      } else {
        userDetails = [
          {
            "registry_question_id": questionIds.myRole,
            "question_answer": selectedMyRole,
            "remarks": ""
          },
          {
            "registry_question_id": questionIds.partnerName, 
            "question_answer": partnerName,
            "remarks": selectedPartnerRole
          },
          {
            "registry_question_id": questionIds.arrivalDate || questionIds.eventDate,
            "question_answer": formattedDate,
            "remarks": "Event Date"
          }
        ];
      }
  
      await api.saveUserDetailsAnswers(accessToken, stagingRegistryId, userDetails);
  
    } catch (error) {
      console.error("Error saving user details answers:", error.message);
      showToast('Failed to save previous answers', "error");
    }
  };

  const toggleVisitorPasswordVisibility = () => {
    setShowVisitorPassword(!showVisitorPassword);
  };

  const handlePasswordChange = (e) => {
    const password = e.target.value;
    setVisitorPassword(password);

    if (password.trim() === '') {
      setIsVisitorPasswordValid(false);
      showToast('Please enter a visitor password');
    } else {
      setIsVisitorPasswordValid(true);
    }
  };

  const handleCompleteRegistry = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      
      const { description } = questionsByRegistryType[registryTypeId];
      const registryDetails = {
        user_id: parseInt(userId),
        registry_type_id: registryTypeId,
        description: description || null,
        public: allowFoundOnQuickRegistry,
        password_option: visitorPasswordCheckbox,
        profile_image: croppedImage || null,
        visitor_password: visitorPasswordCheckbox ? visitorPassword : null,
        address: {
          first_name: firstName,
          last_name: lastName,
          address_1: address1,
          address_2: address2 || null,
          city: city,
          state: state,
          country: country,
          zip_code: zipCode,
          instructions: ""
        },
        mobile_no: phoneNo 
      };
  
      await api.completeRegistryDetails(accessToken, stagingRegistryId, registryDetails);
      if(fromMobileApp){
        handleContinue();
      }
    } catch (error) {
      console.error("Error saving user other answers:", error.message);
      if (fromMobileApp) {
        showToast('Failed to create registry. Please try again', 'error');
      } else {
        showToast('Failed to save previous answers', 'error');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleButtonChange = (button) => {
    setActiveButton(button);
  };

  const handleContinue = () => {
    const registryInfo = {
      registryId: stagingRegistryId,
      registryTypeId: registryTypeId,
      registryTypeName: registryTypeName,
      registryPublic: allowFoundOnQuickRegistry,
    };
  
    Object.entries(registryInfo).forEach(([key, value]) => {
      Cookies.set(key, value, { expires: 30, sameSite: 'None', secure: true });
      localStorage.setItem(key, value);
    });
  
    navigate("/dashboard");
    showToast('"Hooray! Your registry is now ready to use!', "success");
  };

  const handleLogout = async () => {
    try {
      await api.logout(accessToken);
      GoogleAuth.signOut();
      clearRegistryCredentials();
      navigate("/");
    } catch (error) {
      clearRegistryCredentials();
      navigate("/");
      console.error("Logout error:", error.message);
    }
  };

  const handleiOSUpload = async () => {
    try {
      const photo = await Camera.getPhoto({
        resultType: CameraResultType.Uri,
        source: CameraSource.Photos,
        quality: 100
      });
      
      const imageUrl = photo.webPath;
      
      const img = new Image();
      img.src = imageUrl;
      
      img.onload = () => {
        setSelectedImage(imageUrl);
      
        $("#image-modal").modal("show");
      };
      
      img.onerror = (error) => {
        console.error('Error loading image:', error);
      };
    } catch (error) {
      console.error('Error selecting photo', error);
    }
  };

  const handleProfilePictureChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
      if (validImageTypes.includes(file.type)) {
        const reader = new FileReader();
        reader.onload = () => {
          setSelectedImage(reader.result);
          $('#image-modal').modal('show');
        };
        reader.readAsDataURL(file);
      } else {
        showToast("Please upload a valid image file (e.g., PNG, JPEG, JPG)", "error");
      }
    }
  };

  const getCroppedImg = (imageSrc, crop, fileName) => {
    const canvas = document.createElement('canvas');
    const image = new Image();
    image.src = imageSrc;

    return new Promise((resolve, reject) => {
      image.onload = () => {
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height
        );

        canvas.toBlob((blob) => {
          if (!blob) {
            reject(new Error('Canvas is empty'));
            return;
          }
          blob.name = fileName;
          const fileUrl = window.URL.createObjectURL(blob);
          resolve(fileUrl);
        }, 'image/jpeg');
      };
    });
  };

  const onCropChange = (newCrop) => {
    setCrop(newCrop);
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleCropProfileImage = async () => {
    try {
      const croppedImgUrl = await getCroppedImg(selectedImage, croppedAreaPixels, 'cropped.jpg');
      const reader = new FileReader();
  
      const imageBlob = await fetch(croppedImgUrl).then(res => res.blob());
      reader.readAsDataURL(imageBlob);
  
      reader.onloadend = () => {
        const base64Image = reader.result;
        setCroppedImage(base64Image);
        $('#image-modal').modal('hide');
      };
    } catch (error) {
      console.error("Error cropping image:", error);
    }
  };

  const handleCancelCrop = () => {
    $('#image-modal').modal('hide');
    setSelectedImage(null);
    setCrop({ x: 0, y: 0 });
    setZoom(1);
  };

  const renderImageModal = () => (
    <div className="modal fade" id="image-modal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog common-modal-popup">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleCancelCrop}></button>
          </div>
          <div className="modal-body row">
            <div className="crop-container">
              <Cropper
                image={selectedImage}
                crop={crop}
                zoom={zoom}
                aspect={1}
                onCropChange={onCropChange}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                cropShape="round"
              />
            </div>
            <div className="slider-container">
              <input
                type="range"
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                onChange={(e) => setZoom(e.target.value)}
                className="zoom-range"
              />
            </div>
          </div>
          <div className="user-detials-btn text-center mb-4">
            <button type="button" className="common-modal-btn common-btn" onClick={handleCancelCrop}>CANCEL</button>
            <button
              type="button"
              className="btn common-modal-btn common-btn active-btn"
              onClick={handleCropProfileImage}
            >
              DONE
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="wrapper animsition">
      <div className="progress" style={{ height: "7px" }}>
        <div
          className="progress-bar"
          role="progressbar"
          style={{ width: "0%" }}
          aria-valuenow="0"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>

      <div className="wrapper-inner">
        <main className="main">
          <article>
            <section className="onboarding-process text-center">
              <div className="container">
                <div className="row">
                  <div className="process-box">
                    <div className="col-md-12">
                    {fromiOSDevice && <div className="spacing-mid"></div>}
                      <div className="spacing-mid"></div>
                      <Link className="navbar-logo" to="#">
                        <img
                          src="assets/images/logo.png"
                          alt="logo"
                          loading="lazy"
                        />
                      </Link>
                      <div className="spacing-mid"></div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <form id="multi-step-form" noValidate>
                    <div className="step step-1">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="process-heading">
                            <h2>What would you like to create?</h2>
                            <div className="spacing-mid"></div>
                          </div>
                        </div>
                        <div className="row justify-content-center">
                          <div className="col-lg-3 col-md-6 col-sm-6">
                              <div className="registry-box">
                                  <button type="button" className="next-step" onClick={() => handleRegistrySelection("1", "My Baby Registry")}>
                                      <h4>Baby Registry</h4>
                                      <img src="assets/images/baby-registry.svg" alt="baby-registry" />
                                  </button>
                              </div>
                          </div>
                          <div className="col-lg-3 col-md-6 col-sm-6">
                              <div className="registry-box">
                                  <button type="button" className="next-step" onClick={() => handleRegistrySelection("2", "My Wedding Registry")}>
                                      <h4>Wedding Registry</h4>
                                      <img src="assets/images/marriage-registry.svg" alt="marriage-registry" />
                                  </button>
                              </div>
                          </div>
                          <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="registry-box">
                                <button type="button" className="next-step" onClick={() => handleRegistrySelection("3", "Giftlist")}>
                                    <h4>Gift list</h4>
                                    <img src="assets/images/gift.svg" alt="gift" />
                                </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="step step-2">
                      <div className="col-lg-12">
                        <div className="process-heading">
                          <h2>Hi, {storedUserName}. We’re happy you’re here!</h2>
                          <div className="spacing-mid"></div>
                        </div>
                        <p className="text-center f-stp-heading mb-5 fs-3">
                          {questionsByRegistryType[registryTypeId].sequence1.question}
                        </p>
                        <div className="arrival-date align-left">
                          <div className="user-box row">
                            <div className="col-md-12">
                              {questionsByRegistryType[registryTypeId].sequence1.options ? (
                                <div className="user-box partner-name mb-4">
                                  {questionsByRegistryType[registryTypeId].sequence1.options.map(
                                    (option) => (
                                      <Link
                                        key={option}
                                        className={
                                          selectedMyRole === option ? "active-role-btn" : ""
                                        }
                                        onClick={() => setSelectedMyRole(option)}
                                      >
                                        {option}
                                      </Link>
                                    )
                                  )}
                                </div>
                              ) : (
                                <div className="adjust-space"></div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="onboarding-btn">
                          <button
                            type="button"
                            className="btn btn-primary main-btn next-step"
                            disabled={
                              questionsByRegistryType[registryTypeId].sequence1.options &&
                              selectedMyRole === ""
                            }
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="step step-3">
                      <div className="col-lg-12">
                        <div className="process-heading">
                          <h2>{questionsByRegistryType[registryTypeId].sequence2.question}</h2>
                          <div className="spacing-mid"></div>
                        </div>
                        <div className={`arrival-date ${registryTypeId === "3" ? "text-center" : "text-left"}`}>
                          <p className="mb-4">
                            {registryTypeId === "3"
                              ? "This will help visitors find the right list."
                              : "This is an optional step. you can always add it later."}
                          </p>
                          {registryTypeId === "3" ? (
                            <div className="user-box row justify-content-center">
                              <div className="col-md-6">
                                <div className="user-box text-center">
                                  <input
                                    type="text"
                                    name="Gift List Name"
                                    onChange={handleGiftListNameChange}
                                    autoComplete="offAuto"
                                    value={giftListName}
                                    maxLength="30"
                                    required
                                  />
                                  <label>Gift List Name</label>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="user-box row">
                              <div className="col-md-6">
                                <div className="user-box">
                                  <input
                                    type="text"
                                    name="First Name"
                                    onChange={handleFirstNameChange}
                                    autoComplete="offAuto"
                                    value={partnerFirstName}
                                    required
                                  />
                                  <label>First Name</label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="user-box">
                                  <input
                                    type="text"
                                    name="Last Name"
                                    onChange={handleLastNameChange}
                                    autoComplete="offAuto"
                                    value={partnerLastName}
                                    required
                                  />
                                  <label>Last Name</label>
                                </div>
                              </div>
                            </div>
                          )}
                          {questionsByRegistryType[registryTypeId].sequence2.options ? (
                            <div className="user-box row">
                              <div className="col-md-12">
                                <div className="user-box partner-name mb-4">
                                  {questionsByRegistryType[registryTypeId].sequence2.options.map(
                                    (option) => (
                                      <Link
                                        key={option}
                                        className={
                                          selectedPartnerRole === option ? "active-role-btn" : ""
                                        }
                                        onClick={() => setSelectedPartnerRole(option)}
                                      >
                                        {option}
                                      </Link>
                                    )
                                  )}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="adjust-space"></div>
                          )}
                        </div>
                        <div className="onboarding-btn">
                          <button
                            type="button"
                            className="btn btn-primary prev-step"
                          >
                            Previous
                          </button>&nbsp;
                          <button
                            type="button"
                            className="btn btn-primary main-btn next-step"
                            disabled={registryTypeId === "3" ? !validateGiftlistDetails() : !validatePartnerDetails()}
                            onClick={() => {
                              if (registryTypeId === "3") {
                                handleUpdateGiftlist();
                              }
                            }}
                          >
                            Next
                          </button>
                        </div>
                        <div className="skip-container">
                          <button
                            type="button"
                            className="btn skip-btn-text next-step"
                            onClick={handleSkipDetails}
                          >
                            Skip for Now
                          </button>
                        </div>
                        {registryTypeId === "3" && (
                          <div className="mt-2">
                            <p>You can always name your Gift List later</p>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="step step-4">
                      <div className="col-lg-12">
                        <div className="process-heading">
                          <h2 className="mb-4">
                            {questionsByRegistryType[registryTypeId].sequence3.question}
                          </h2>
                          <h4>You can add it now and change it later</h4>
                          <div className="spacing-mid"></div>
                        </div>
                        <div className="arrival-date align-left mb-5">
                          <p className="mb-2">
                            {questionsByRegistryType[registryTypeId].sequence3.inputLabel}
                          </p>
                          <div className="user-box row">
                            <div className="col-md-4">
                              <div className="select-wrapper customized-select">
                              <select
                                name="Month"
                                id="monthSelect"
                                className="form-control"
                                onChange={handleMonthChange}
                              >
                                  <option>Month</option>
                                  <option value="1">January</option>
                                  <option value="2">February</option>
                                  <option value="3">March</option>
                                  <option value="4">April</option>
                                  <option value="5">May</option>
                                  <option value="6">June</option>
                                  <option value="7">July</option>
                                  <option value="8">August</option>
                                  <option value="9">September</option>
                                  <option value="10">October</option>
                                  <option value="11">November</option>
                                  <option value="12">December</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <input
                                type="number"
                                name="Day"
                                id="dayInput"
                                className="form-control"
                                placeholder="Day"
                                value={day}
                                onChange={handleDayChange}
                                autoComplete="off"
                                min="1"
                                max="31"
                              />
                            </div>
                            <div className="col-md-4">
                              <input
                                type="number"
                                name="Year"
                                id="yearInput"
                                className="form-control"
                                placeholder="Year"
                                value={year}
                                onChange={handleYearChange}
                                autoComplete="off"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="onboarding-btn">
                          <button
                            type="button"
                            className="btn btn-primary prev-step"
                          >
                            Previous
                          </button>&nbsp;
                          <button
                            type="button"
                            className="btn btn-primary main-btn next-step"
                            onClick={handleSaveDetailsAnswers}
                            disabled={!validateEventDate()}
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="step step-5">
                      <div className="col-lg-12">
                        <div className="process-heading">
                          <h2>Where would you like your gifts sent?</h2>
                          <div className="spacing-mid"></div>
                        </div>
                        <div className="arrival-date align-left">
                          <p className="mb-3">
                            All fields are required unless otherwise specified.
                          </p>
                          <div className="user-box row">
                            <div className="col-md-6">
                              <div className="user-box">
                                <input
                                  type="text"
                                  name="First Name"
                                  value={firstName}
                                  onChange={(e) => setFirstName(e.target.value)}
                                  autoComplete="offAuto"
                                  required
                                />
                                <label>First Name</label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="user-box">
                                <input
                                  type="text"
                                  name="Last Name"
                                  value={lastName}
                                  onChange={(e) => setLastName(e.target.value)}
                                  autoComplete="offAuto"
                                  required
                                />
                                <label>Last Name</label>
                              </div>
                            </div>
                          </div>
                          <div className="user-box row">
                            <div className="col-md-12">
                              <div className="user-box">
                                <input
                                  type="text"
                                  name="Address 1"
                                  value={address1}
                                  onChange={(e) => setAddress1(e.target.value)}
                                  autoComplete="offAuto"
                                  required
                                />
                                <label>Address 1</label>
                              </div>
                              <div className="user-box">
                                <input
                                  type="text"
                                  name="Address 2"
                                  value={address2}
                                  onChange={(e) => setAddress2(e.target.value)}
                                  autoComplete="offAuto"
                                  required
                                />
                                <label>Address 2 (optional)</label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="user-box">
                                <input
                                  type="text"
                                  name="City"
                                  value={city}
                                  onChange={(e) => setCity(e.target.value)}
                                  autoComplete="offAuto"
                                  required
                                />
                                <label>City</label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="user-box">
                                <input
                                  type="number"
                                  name="Zip Code"
                                  value={zipCode}
                                  onChange={(e) => {
                                    const enteredValue = e.target.value;
                                    if (enteredValue.length <= 6) {
                                      setZipCode(enteredValue);
                                    }
                                  }}
                                  autoComplete="offAuto"
                                  required
                                />
                                <label>Pin Code</label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="user-box">
                                <div className="select-wrapper customized-select">
                                  <select
                                    name="State"
                                    id="stateSelect"
                                    className="form-control"
                                    value={state}
                                    onChange={(e) => setState(e.target.value)}
                                  >
                                    <option value="">Select State</option>
                                    {indianStates.map((stateName, index) => (
                                      <option key={index} value={stateName}>
                                        {stateName}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="user-box">
                                <input
                                  type="text"
                                  name="Country"
                                  value={country}
                                  disabled
                                  className="form-control"
                                />
                                <label>Country</label>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="user-box">
                                <input
                                  type="tel"
                                  name="Phone No."
                                  value={phoneNo}
                                  onChange={(e) => {
                                    const enteredValue = e.target.value;
                                    if (enteredValue.length <= 10) {
                                      setPhoneNo(enteredValue);
                                    }
                                  }}
                                  autoComplete="offAuto"
                                  required
                                />
                                <label>Phone No.</label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="onboarding-btn">
                          <button
                            type="button"
                            className="btn btn-primary prev-step"
                          >
                            Previous
                          </button>&nbsp;
                          <button
                          type="button"
                          className="btn btn-primary main-btn next-step"
                          disabled={!validateForm()}
                        >
                          Next
                        </button>
                        </div>
                      </div>
                    </div>
                    <div className="step step-6">
                      <div className="col-lg-12">
                        <div className="process-heading">
                          <h2>Additional settings</h2>
                          <div className="spacing-small"></div>
                        </div>
                        <div className="arrival-date additional-setting">
                          <div className="user-box mb-4">
                            <div className="checkbox">
                              <h4 className="mb-3"> Choose Your Registry Visibility</h4>
                              <label className="checkbox-text mb-3">How would you like your registry to be viewed?</label>
                              <div className="radio-option">
                                <input
                                  type="radio"
                                  id="public"
                                  name="registryVisibility"
                                  value="public"
                                  checked={allowFoundOnQuickRegistry}
                                  onChange={() => setAllowFoundOnQuickRegistry(true)}
                                  className="custom-radio"
                                />
                                <label htmlFor="public" className="checkbox-text mb-2">Public (Recommended)</label>
                              </div>

                              <div className="radio-option">
                                <input
                                  type="radio"
                                  id="private"
                                  name="registryVisibility"
                                  value="private"
                                  checked={!allowFoundOnQuickRegistry}
                                  onChange={() => setAllowFoundOnQuickRegistry(false)}
                                  className="custom-radio"
                                />
                                <label htmlFor="private" className="checkbox-text">Private Link - Only those with your registry link can access it.</label>
                              </div>
                            </div>

                            <div className="checkbox">
                              <input type="checkbox" checked={visitorPasswordCheckbox}
                                 onChange={() => {
                                  setVisitorPasswordCheckbox(!visitorPasswordCheckbox);
                                  setIsVisitorPasswordValid(true);
                                }}
                              />
                              <label className="checkbox-text">&nbsp; Enable a Visitor Password</label>&nbsp;
                              <HtmlTooltip
                                title={
                                  <>
                                    <span>
                                      If you disable visitor password, your shipping address will be visible to visitors on your{" "}
                                      {registryTypeId === "3" ? "giftlist" : "registry"} directly.
                                    </span>
                                  </>
                                }
                                placement={isPortrait ? "bottom" : "right"}
                                interactive
                              >
                                <InfoIcon src="assets/images/icon/info-icon.svg" alt="info-icon" className="info-icon" />
                              </HtmlTooltip>
                            </div>
                            {visitorPasswordCheckbox && (
                              <div className="user-box mb-3">
                                <input
                                  name="visitor-password"
                                  type={showVisitorPassword ? "text" : "password"}
                                  value={visitorPassword}
                                  placeholder="Password"
                                  maxLength={20}
                                  className={`form-control ${isVisitorPasswordValid ? '' : 'is-invalid'}`}
                                  onKeyPress={(e) => e.key === 'Enter' && handleCompleteRegistry(e)}
                                  onChange={handlePasswordChange}
                                  required
                                />
                                <p className="password-char-visitor">
                                  Maximum 20 characters
                                </p>
                                <Link
                                  className="password-toggle-icon position-absolute end-0 bottom-50 me-3"
                                  onClick={toggleVisitorPasswordVisibility}
                                >
                                  {showVisitorPassword ? (
                                    <i className="las la-eye-slash fs-4"></i>
                                  ) : (
                                    <i className="las la-eye fs-4"></i>
                                  )}
                                </Link>
                              </div>
                            )}
                            <div className="checkbox">
                              <input
                                type="checkbox"
                                checked={enableProfilePicture}
                                onChange={() => {
                                  setEnableProfilePicture(!enableProfilePicture);
                                  if (enableProfilePicture) {
                                    setCroppedImage(null);
                                  }
                                }}
                              />
                              <label className="checkbox-text">&nbsp; Upload Registry Profile Picture</label>
                            </div>
                            {enableProfilePicture && (
                              <>
                                <div className="user-box text-center mb-3">
                                  <img
                                    src={croppedImage || "/assets/images/profile_picture.png"}
                                    alt="Profile"
                                    className="img-thumbnail"
                                    style={{ width: '110px', height: '110px', borderRadius: '50%' }}
                                  />
                                  <div className="user-detials-btn text-center mt-3">
                                    <button
                                      type="button"
                                      className="btn common-modal-btn common-btn"
                                      onClick={fromiOSDevice ? handleiOSUpload : () => document.getElementById('profile-upload').click()}
                                    >
                                      {croppedImage ? 'Change' : 'Upload'} Image
                                    </button>
                                    {!fromiOSDevice && (
                                      <input
                                        type="file"
                                        id="profile-upload"
                                        style={{ display: 'none' }}
                                        onChange={(e) => {
                                          handleProfilePictureChange(e);
                                          e.target.value = '';
                                        }}
                                        accept="image/png, image/jpeg, image/jpg"
                                      />
                                    )}
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        <div className="onboarding-btn">
                          <button
                            type="button"
                            className="btn btn-primary prev-step" 
                          >
                            Previous
                          </button>&nbsp;
                          <button
                            type="button"
                            className="btn btn-primary main-btn next-step"
                            onClick={handleCompleteRegistry}
                            disabled={!validatePassword() || !validateProfileImage() || loading}
                          >
                            {loading && fromMobileApp ? (
                              <>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> &nbsp;Processing
                              </>
                            ) : (
                              fromMobileApp ? "Continue" : "Next"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="step step-7">
                      <div className="col-lg-12">
                        <div className="process-heading mb-5">
                          <h2>Last thing…</h2>
                          <div className="spacing-small last-thing-desc">
                            <p>The “Quick Registries” Button is what makes it possible to add items from any store in the world to your gift list on QuickRegistries.com</p>
                          </div>
                        </div>
                        <div className="last-steps-container">
                          <div className="last-steps-info-container">
                            <div className="how-it-works-container">
                              <p className="last-step-heading">How it works</p>
                            </div>

                            <div className="onboarding-btn point-btn my-1">
                              <button
                                type="button"
                                className={`btn btn-primary mt-2 ${activeButton === 'extension' ? 'active-point-btn' : ''}`}
                                onClick={() => handleButtonChange('extension')}
                              >
                                Extension
                              </button>
                              <span className="or-text-onboard">or</span>
                              <button
                                type="button"
                                className={`btn btn-primary mt-2 ${activeButton === 'bookmark' ? 'active-point-btn' : ''}`}
                                onClick={() => handleButtonChange('bookmark')}
                              >
                                Bookmark
                              </button>
                            </div>
                          </div>

                          {activeButton === 'extension' && (
                            <>
                              <div className="last-steps-info-container">
                                <span className="mulitple-list-number">1</span>
                                <span className="last-steps-details-container">
                                  <span className="last-step-heading">
                                    <Link
                                      to="https://chromewebstore.google.com/detail/quick-registry/bpfmfjcokemkfaofkheknefimcijonke?hl=en&authuser=8"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="change-new-color"
                                    > Click here </Link>
                                    
                                    or to add and <Link to="https://support.google.com/chrome/a/answer/11190170?hl=en" target="_blank" className="change-new-color">pin it</Link> to your browser
                                  </span>
                                  <br />
                                  <span>Just like your other extensions, it takes just a few clicks.</span>
                                </span>
                              </div>
                              <div className="last-steps-info-container add-qr-container">
                                <div className="onboarding-btn add-chrome-btn">
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => window.open("https://chromewebstore.google.com/detail/quick-registry/bpfmfjcokemkfaofkheknefimcijonke?hl=en&authuser=8", '_blank')}
                                  >
                                    Add to Chrome
                                  </button>
                                </div>
                              </div>
                              <div className="last-steps-info-container">
                                <span className="mulitple-list-number">2</span>
                                <span className="last-steps-details-container">
                                  <span className="last-step-heading">Find a gift you would like</span>
                                  <br />
                                  <span>Visit any retail website and go to the product page of the gift you’d like to add.</span>
                                </span>
                              </div>
                              <div className="last-steps-info-container">
                                <span className="mulitple-list-number">3</span>
                                <span className="last-steps-details-container">
                                  <span className="last-step-heading">Add in Seconds</span>
                                  <br />
                                  <span>Click on the button in your browser, confirm some details, add the gift.</span>
                                </span>
                              </div>
                            </>
                          )}

                          {activeButton === 'bookmark' && (
                            <>
                              <div className="last-steps-info-container">
                                <span className="mulitple-list-number">1</span>
                                <span className="last-steps-details-container">
                                  <span className="last-step-heading">Show bookmarks</span>
                                  <br />
                                  <span>If you don’t see your bookmarks bar, go to the top right menu and select <span className="less-bold">Bookmarks &gt; Show Bookmarks Bar</span>.</span>
                                </span>
                              </div>
                              <div className="last-steps-info-container">
                                <span className="mulitple-list-number">2</span>
                                <span className="last-steps-details-container">
                                  <span className="last-step-heading">Add the button to your bookmarks bar</span>
                                  <br />
                                  <span>Drag the below<span className="less-bold"> Add To QR List </span>button to your bookmarks bar.</span>
                                </span>
                              </div>
                              <div className="last-steps-info-container add-qr-container">
                                <div className="button">
                                  <a
                                    id="btnBookmark"
                                    className="button-qr-outline"
                                    // eslint-disable-next-line no-script-url
                                    href="javascript:(function(){var currentDomain=window.location.hostname;var blockedDomains=['www.flipkart.com','www.nykaafashion.com'];var currentUrl=encodeURIComponent(window.location.href);if(blockedDomains.includes(currentDomain)){window.location.href='https://quickregistries.com/script-blocked?url='+currentUrl;}else{var iframeUrl='https://quickregistries.com/add-gift-modal';var iframeSrc=iframeUrl+'?fromWeb=true&url='+currentUrl;if(!document.getElementById('AddToMr3Iframe')){var overlay=document.createElement('div');overlay.id='overlay';document.body.appendChild(overlay);overlay.innerHTML='<div id=\'backgroundOverlay\' style=\'position: fixed; top: 0; left: 0; width: 100%; height: 100%; background-color: #ddd; opacity: 0.9; z-index: 2147483646;\'></div><iframe id=\'AddToMr3Iframe\' src=\''+iframeSrc+'\' style=\'display: none; position: fixed; height: 600px; width: 900px; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 2147483647; opacity: 1; clip: rect(26px, 791px, 570px, 91px);\'></iframe>';var iframe=document.getElementById('AddToMr3Iframe');iframe.addEventListener('load',function(){var pageHTMLContent=document.documentElement.outerHTML;iframe.contentWindow.postMessage({action:'sendHTML',pageHTMLContent:pageHTMLContent},'*');});setTimeout(function(){iframe.style.display='block';},6000);window.addEventListener('message',function(event){if(event.data.action==='closeIframe'){document.body.style.overflow='visible';var iframe=document.getElementById('AddToMr3Iframe');if(iframe){iframe.parentNode.removeChild(iframe);var overlay=document.getElementById('overlay');if(overlay){overlay.parentNode.removeChild(overlay);}}}});}}})();"
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    🎁Add To QR List
                                  </a>
                                </div>
                              </div>
                              <div className="last-steps-info-container">
                                <span className="mulitple-list-number">3</span>
                                <span className="last-steps-details-container">
                                  <span className="last-step-heading">Find a gift you would like</span>
                                  <br />
                                  <span>Visit any retail website and go to the product page of the gift you’d like to add.</span>
                                </span>
                              </div>
                              <div className="last-steps-info-container">
                                <span className="mulitple-list-number">4</span>
                                <span className="last-steps-details-container">
                                  <span className="last-step-heading">Add in Seconds</span>
                                  <br />
                                  <span>Click on the button in your bookmarks bar, confirm some details, add the gift.</span>
                                </span>
                              </div>
                            </>
                          )}
                        </div>
                        <div className="onboarding-btn mt-3">
                          <button
                            type="button"
                            className="btn btn-primary prev-step" 
                          >
                            Previous
                          </button>&nbsp;
                          <button
                            type="button"
                            className="btn btn-primary main-btn next-step"
                            onClick={handleContinue}
                          >
                            Continue
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="row">
                  {currentStep === 1 && (
                    <div className="col-md-12">
                      <div className={`back-btn-text ${fromiOSDevice && 'ios-area'}`}>
                        <p>
                          <Link className="back-button" onClick={() => navigate(-1)}>
                            <i className="la la-angle-left"></i> Back
                          </Link>
                        </p>
                      </div>
                    </div>
                  )}
                  <div className="col-md-12">
                    <div className={`login-text ${fromiOSDevice && 'ios-area'}`}>
                      <p className="fs-6 mt-1">WELCOME, {storedUserName.toUpperCase()} 
                        <button type="button" className="log-out">
                          <i className="la la-angle-down"></i>
                        </button>
                        <ul className="overlay">
                          {!initialRegistry && (
                            <li>
                              <Link to="/all-registry">Manage Registries</Link>
                            </li>
                          )}
                          <li>
                            <Link to="#" onClick={handleLogout}><i className="la la-sign-out-alt fs-6 me-1"></i>Log Out</Link>
                          </li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
             <LoginModal/>
            </section>
          </article>
        </main>
        {renderImageModal()}
      </div>
    </div>
  );
};

export default OnboardingProcessPage;
