/* global $ */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { showToast } from "../../../utils/toastUtils";
import { getRegistryCredentials } from '../../../utils/registryUtils';
import api from "../../../services/api";

const PurchaserDetailsModal = ({itemId, fetchRegistryItems, fetchRegistryDetails, currentPage, setCurrentPage}) => {
  const [purchaserInformation, setPurchaserInformation] = useState([]);
  const [itemName, setItemName] = useState("");
  const [purchaserId, setPurchaserId] = useState(null);
  const { accessToken } = getRegistryCredentials();

  const fetchPurchaserInformation = async () => {
    try {
      const response = await api.getPurchaserDetails(accessToken, itemId);
      setPurchaserInformation(response.data);
      setItemName(response.data[0]?.item_name);
    } catch (error) {
      console.error("Error fetching buyer information:", error);
    }
  };

  useEffect(() => {
    if (itemId) {
      fetchPurchaserInformation();
    }
    // eslint-disable-next-line
  }, [itemId]);

  const formatPurchasedDate = (dateString) => {
    const date = new Date(dateString);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  const formatSecretDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };

  const handleRestoreDialog = (purchaserId) => {
    $("#purchaser-details").modal("hide");
    $("#restore-modal").modal("show");
    setPurchaserId(purchaserId);
  }

  const handleConfirmRestore = async () => {
    try {
      await api.deletePurchaserDetails(accessToken, purchaserId);

      showToast('Gift list was updated successfully', "success");
      
      fetchPurchaserInformation();
      if (currentPage === 1) {
        fetchRegistryItems();
      } else {
        setCurrentPage(1);
      }
      fetchRegistryDetails();
      $("#restore-modal").modal("hide");
      if (purchaserInformation.length > 1) {
        $("#purchaser-details").modal("show");
      }
    } catch (error) {
      console.error("Error restoring purchased gift:", error.message);
      showToast('Failed to restore gift', "error");
    } finally {
      setPurchaserId(null);
    }
  }

  const handleCancelRestore = () => {
    $("#restore-modal").modal("hide");
    $("#purchaser-details").modal("show");
  }  

  const handleRevealBuyer = (purchaserId) => {
    setPurchaserId(purchaserId);
    $("#purchaser-details").modal("hide");
    $("#reveal-buyer").modal("show");
  }

  const handleConfirmBuyer = async () => {
    try {
      await api.revealPurchaserDetails(accessToken, purchaserId);

      fetchPurchaserInformation();
      $("#reveal-buyer").modal("hide");
      setTimeout(() => {
        $("#purchaser-details").modal("show");
      }, 300)
      showToast('Purchaser details revealed successfully', "success");
    } catch (error) {
      console.error("Error revealing purchaser details:", error.message);
      showToast('Failed to reveal purchaser details', "error");
    } finally {
      setPurchaserId(null);
    }
  }

  const handleCancelBuyer = () => {
    $("#reveal-buyer").modal("hide");
    $("#purchaser-details").modal("show");
  }  

  return (
    <>
      <div className="modal fade" id="purchaser-details" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog common-popup purchaser-details-popup">
          <div className="modal-content">
            <div className="modal-header">
              <p>CONTRIBUTOR(S) INFORMATION</p>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body mt-3">
              <p className="purchaser-item-name clamp-three-lines mb-3">{itemName}</p>
              <div className="table-responsive">
                <table className="table custom-table">
                  <thead className="custom-thead">
                    <tr>
                      <th>GIFT GIVER NAME</th>
                      <th className="text-center">QUANTITY</th>
                      <th className="text-center">DATE</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody className="small-row">
                    {purchaserInformation.slice(0).reverse().map((buyer, index) => (
                      <tr key={index}>
                        <td className="purchaser-name-column">
                          {buyer.secret ? (
                            <>
                              <span className="purchaser-name">IT'S A SURPRISE UNTIL {formatSecretDate(buyer.secret_open)}</span>
                              <br />
                              <span className="purchaser-email"><Link to="#" className="restore-link" onClick={() => handleRevealBuyer(buyer.id)}>Take a peek</Link></span>
                            </>
                          ) : (
                            <>
                              <span className="purchaser-name">{buyer.name}</span><br />
                              <span className="purchaser-email">{buyer.email}</span>
                            </>
                          )}
                        </td>
                        <td className="align-middle text-center">{buyer.qty}</td>
                        <td className="align-middle text-center">{formatPurchasedDate(buyer.purchased_date)}</td>
                        <td className="align-middle"><Link to="#" className="restore-link" onClick={() => handleRestoreDialog(buyer.id)}>RESTORE</Link></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="restore-modal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body row">
              <div className="col-lg-12">
                <div className="confirmation-modal text-center mt-4">
                  <h5 className="mb-4">Mark gift as available</h5>
                  <p>Do you want to make this item available again on the gift list so it can be gifted?</p>
                </div>
                <div className="user-detials-btn text-center mt-4 mb-4">
                  <button type="button" className="common-modal-btn common-btn" onClick={handleCancelRestore}>NO</button>
                  <button type="button" className="common-modal-btn common-btn active-btn" onClick={handleConfirmRestore}>YES</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="reveal-buyer" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body row">
              <div className="col-lg-12">
                <div className="confirmation-modal text-center mt-4">
                  <h5 className="mb-4">Are you Sure?</h5>
                  <p>The name of this gift giver is hidden at their request. Are you sure you want to reveal their info?</p>
                </div>
                <div className="user-detials-btn text-center mt-4 mb-3">
                  <button type="button" className="common-modal-btn common-btn mb-3" onClick={handleCancelBuyer}>NO, THANKS</button>
                  <button type="button" className="common-modal-btn common-btn active-btn" onClick={handleConfirmBuyer}>YES, SHOW ME</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PurchaserDetailsModal;
