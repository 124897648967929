/* global $ */
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Header from "../../components/common/Header";
import Navbar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";
import { useMediaQuery } from '@mui/material';
import { showToast } from "../../utils/toastUtils";
import { getRegistryCredentials } from "../../utils/registryUtils";
import { InfoIcon, HtmlTooltip, HtmlTooltips } from '../../utils/tooltipUtils';
import BuyGiftModal from "../../components/dashboard/modal/BuyGiftModal";
import VerificationModal from "../../components/dashboard/modal/VerificationModal";
import BuyMultipleGiftsModal from "../../components/dashboard/modal/BuyMultipleGiftsModal";
import api from "../../services/api";

const ViewProductPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isPortrait = useMediaQuery('(max-width:575px)');
  const isNavbarVisible = useMediaQuery('(max-width:991px)');
  const searchParams = new URLSearchParams(location.search);
  const itemId = searchParams.get("itemId");
  const regId = searchParams.get("regId");
  const [productData, setProductData] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [shippingData, setShippingData] = useState(null);
  const [myPublicKey, setMyPublicKey] = useState(null);
  const [yourName, setYourName] = useState('');
  const [yourPhoneNumber, setYourPhoneNumber] = useState('');
  const [visitorPassword, setVisitorPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [hasPassword, setHasPassword] = useState(false);
  const [isYourNameValid, setIsYourNameValid] = useState(true);
  const [passwordProtected, setPasswordProtected] = useState(false);
  const [verifyingPassword, setVerifyingPassword] = useState(false);
  const [requestingPassword, setRequestingPassword] = useState(false);
  const [isYourPhoneNumberValid, setIsYourPhoneNumberValid] = useState(true);
  const [isVisitorPasswordValid, setIsvisitorPasswordValid] = useState(true);
  const [loading, setLoading] = useState(true);
  const { accessToken } = getRegistryCredentials();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const itemId = searchParams.get("itemId");
    const regId = searchParams.get("regId");
  
    if (itemId && regId) {
      sessionStorage.setItem('registryParams', JSON.stringify({ itemId, regId }));
    }  
  
    if (!itemId || !regId) {
      const storedParams = JSON.parse(sessionStorage.getItem('registryParams'));
      if (storedParams && storedParams.itemId && storedParams.regId) {
        const { itemId: storedItemId, regId: storedRegId } = storedParams;
        navigate(`?itemId=${storedItemId}&regId=${storedRegId}`);
      }
    }
  
  }, [location.search, navigate]);

  const fetchProductData = async (visitorPassword = '') => {
    setLoading(true);
    try {
      if (itemId && regId) {
        const response = await api.getVisitorItem(itemId, regId, visitorPassword);
  
        if (response.password_protected && !visitorPassword) {
          setPasswordProtected(true);
          setUserDetails({
            firstName: response.user_first_name,
            lastName: response.user_last_name,
            userAnswers: response.user_answers,
          });
          setMyPublicKey(response.public_key);
  
          const storedPassword = localStorage.getItem(response.public_key);
  
          if (storedPassword) {
            const decodedPassword = atob(storedPassword);
  
            try {
              const passwordResponse = await api.getVisitorItem(itemId, regId, decodedPassword);
  
              setProductData(passwordResponse.data);
              setUserDetails({
                firstName: passwordResponse.data.user_first_name,
                lastName: passwordResponse.data.user_last_name,
                userAnswers: passwordResponse.data.user_answer,
              });
              setMyPublicKey(passwordResponse.data.public_key);
              setPasswordProtected(false);
            } catch (error) {
              if (error.message === "Invalid Password") {
                setShowPassword(true);
              } else {
                console.error("Error verifying stored password:", error);
              }
            }
          } else {
            setShowPassword(true);
          }
          return;
        }
  
        setProductData(response.data);
        setUserDetails({
          firstName: response.data.user_first_name,
          lastName: response.data.user_last_name,
          userAnswers: response.data.user_answer,
        });
        setMyPublicKey(response.data.public_key);
      }
    } catch (error) {
      console.error("Error fetching product data:", error);
      navigate("/not-found"); 
    } finally {
      setLoading(false);
    }
  };

  const fetchShippingInfo = async () => {
    setLoading(true); 
    const { jsonWebToken } = getRegistryCredentials();
    try {
      if (accessToken) {
        const data = await api.getAddressToVisitor(accessToken, myPublicKey);
        setShippingData(data.shipping_info);
      } else {
        if (jsonWebToken) {
          const data = await api.showAddressToVisitor(jsonWebToken, myPublicKey);
          setShippingData(data.shipping_info);
        } else {
          console.error("Visitor is not verified.");
        }
      }
    } catch (error) {
      console.error("Error fetching shipping data:", error.message);
    } finally {
      setLoading(false);
    } 
  };

  useEffect(() => {
    fetchProductData();
    // eslint-disable-next-line
  }, [itemId, regId]);

  useEffect(() => {
    if (myPublicKey) {
      fetchShippingInfo();
    }
    // eslint-disable-next-line
  }, [accessToken, myPublicKey]);

  const handleVisitorVerification = (e) => {
    e.preventDefault();
    $("#visitor-verification").modal("show");
  }

  const handleAlreadyBought = () => {
    navigate(`/purchase-process?itemId=${itemId}&regId=${regId}&stepNav=2`);
  };

  const handleMultipleGifts = () => {
    $("#BuyMultipleGifts").modal("show");
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleNameChange = (e) => {
    setYourName(e.target.value);
    setIsYourNameValid(true);
  };

  const handlePhoneNumberChange = (e) => {
    const inputValue = e.target.value;
    const numericInput = inputValue.replace(/\D/g, '');
    setYourPhoneNumber(numericInput);
    setIsYourPhoneNumberValid(true);
  };

  const handleRequestPassword = async (e) => {
    e.preventDefault();

    if (yourName.trim() === "" && yourPhoneNumber.trim() === "") {
      setIsYourNameValid(false);
      setIsYourPhoneNumberValid(false);
      showToast("Please enter your name and phone number");
      return;
    }

    if (yourName.trim() === "") {
      setIsYourNameValid(false);
      showToast("Please enter your name");
      return;
    }

    if (yourPhoneNumber.trim() === "") {
      setIsYourPhoneNumberValid(false);
      showToast("Please enter your phone number");
      return;
    }

    const phoneNumberPattern = /^[0-9]{10}$/;
    if (!phoneNumberPattern.test(yourPhoneNumber)) {
      setIsYourPhoneNumberValid(false);
      showToast("Please enter a valid 10-digit phone number");
      return;
    }

    try {
      setRequestingPassword(true);
      await api.requestVisitorPassword(yourName, yourPhoneNumber, myPublicKey);
      setHasPassword(false);
      showToast("Password requested successfully", "success");
      setHasPassword(false);
      clearVisitorDetails();
    } catch (error) {
      console.error("Error requesting password:", error);
      showToast("Failed to request password. Please try again.", "error");
    } finally {
      setRequestingPassword(false);
    }
  };

  const handleVisitorPassword = async (e) => {
    e.preventDefault();
    if (visitorPassword.trim() === "") {
      showToast("Please enter a visitor password");
      setIsvisitorPasswordValid(false);
      return;
    }
    try {
      setVerifyingPassword(true);
      const response = await api.getVisitorItem(itemId, regId, visitorPassword);
      const encodedPassword = btoa(visitorPassword);
      localStorage.setItem(myPublicKey, encodedPassword);

      setProductData(response.data);
      setUserDetails({
        firstName: response.data.user_first_name,
        lastName: response.data.user_last_name,
        userAnswers: response.data.user_answer,
      });
      setMyPublicKey(response.data.public_key);
    } catch (error) {
      if (error.message === "Invalid Password") {
        showToast("Invalid password. Please try again");
        setIsvisitorPasswordValid(false);
      } else {
        console.error('Error verifying password:', error);
        showToast(`Failed to verify password`, "error");
      }
    } finally {
      setVerifyingPassword(false);
    }
  };

  const handlePasswordChange = (e) => {
    setVisitorPassword(e.target.value);
    setIsvisitorPasswordValid(true);
  };

  const handleTogglePassword = () => {
    setHasPassword(!hasPassword);
    clearVisitorDetails();
  };

  const clearVisitorDetails = () => {
    setYourName('');
    setYourPhoneNumber('');
    setVisitorPassword('');
    setIsYourNameValid(true);
    setIsYourPhoneNumberValid(true);
    setIsvisitorPasswordValid(true);
  };

  return (
    <div className="wrapper animsition">
    {loading ? (
        <div className="custom-loader-container fs-5">
          <div className="spinner-border custom-loader" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
      <div className="wrapper-inner">
        <header className="header">
          <Header />
          {isNavbarVisible && <Navbar />}
        </header>
        <main className="main">
          <article>
            {passwordProtected && !productData ? (
              <section className="py-5 ">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="visitor-dashboard mobile-visitor-board text-center">
                        <div className="user-dashboard">
                          <div className="reset-info my-4">
                            {userDetails && (
                              <h4>
                                {userDetails.firstName}
                                {userDetails.userAnswers && ` & ${userDetails.userAnswers[1].question_answer.split(' ')[0]}`}
                                's gift list
                              </h4>
                            )}
                            <p className="mt-4 fs-6">{!hasPassword ? "Requires a password" : "To request the password please add:"}</p>
                          </div>
                          {!hasPassword ? (
                            <div className="user-box visitor-user-box">
                              <input
                                type={showPassword ? "text" : "password"}
                                name="visitor-password"
                                value={visitorPassword}
                                onChange={handlePasswordChange}
                                className={`form-control ${isVisitorPasswordValid ? '' : 'is-invalid'}`}
                                onKeyPress={(e) => e.key === 'Enter' && handleVisitorPassword(e)}
                                maxLength={20}
                                autoComplete="off"
                                required
                              />
                              <label>Password</label>
                              <Link
                                className="password-toggle-icon password-icon position-absolute end-0 top-50 me-3"
                                onClick={(e) => {
                                  e.preventDefault();
                                  togglePasswordVisibility();
                                }}
                              >
                                {showPassword ? (
                                  <i className="las la-eye-slash fs-4"></i>
                                ) : (
                                  <i className="las la-eye fs-4"></i>
                                )}
                              </Link>
                            </div>
                          ) : (
                            <>
                              <div className="user-box visitor-user-box">
                                <input
                                  name="your-name"
                                  value={yourName}
                                  onChange={handleNameChange}
                                  className={`form-control ${isYourNameValid ? '' : 'is-invalid'}`}
                                  onKeyPress={(e) => e.key === 'Enter' && handleRequestPassword(e)}
                                  required
                                />
                                <label>Your Name</label>
                              </div>
                              <div className="user-box visitor-user-box">
                                <input
                                  name="your-phone-number"
                                  value={yourPhoneNumber}
                                  onChange={handlePhoneNumberChange}
                                  className={`form-control ${isYourPhoneNumberValid ? '' : 'is-invalid'}`}
                                  onKeyPress={(e) => e.key === 'Enter' && handleRequestPassword(e)}
                                  maxLength={10}
                                  required
                                />
                                <label>Your Phone Number</label>
                              </div>
                            </>
                          )}
                          {!hasPassword ? (
                            <button
                              type="button"
                              className="btn btn-primary view-gift-btn"
                              onClick={handleVisitorPassword}
                              disabled={verifyingPassword}
                            >
                              {verifyingPassword ? (
                                <>
                                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> &nbsp;Verifying...
                                </>
                              ) : (
                                'VIEW THE GIFT'
                              )}
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="btn btn-primary view-gift-btn"
                              onClick={handleRequestPassword}
                              disabled={requestingPassword}
                            >
                              {requestingPassword ? (
                                <>
                                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> &nbsp;Requesting...
                                </>
                              ) : (
                                'REQUEST PASSWORD'
                              )}
                            </button>
                          )}
                          <div className="primary-button text-center mb-3 mt-4">
                            <button type="button" onClick={handleTogglePassword}>
                              {!hasPassword ? "I don't have the password" : "I have the password"} </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            ) : (
              <section className="py-5">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="dashboard">
                        <div className="user-dashboard view-product-dashboard">
                          <div className="view-visitor-product">
                            <div className="view-visitor-link">
                              <Link to={`/giftlist/${myPublicKey}`}>
                                <i className="la la-angle-left"></i>BACK TO&nbsp;
                                {userDetails?.firstName?.toUpperCase()}
                                {userDetails?.userAnswers.find((answer) => answer.registry_question_id === 2 && answer.question_answer !== null
                                ) && (
                                  <> & {userDetails.userAnswers.find((answer) => answer.registry_question_id === 2).question_answer.split(' ')[0].toUpperCase()} </>
                                )}
                                'S GIFT LIST
                              </Link>
                            </div>
                            <div className="product-popup">
                              <div className="modal-body login-box row">
                                <div className="col-lg-6 col-md-6">
                                  <div className="product-image">
                                    {productData?.image ? (
                                      <img src={productData?.image} alt="" className="view-product-image d-block mx-auto"/>
                                      ) : (
                                      <img src="/assets/images/no_image_available.png" alt="" className="view-product-image img-fluid d-block mx-auto"/>
                                    )}
                                  </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                  <div className="product-form">
                                    <div className="view-visitor-product-info clamp-three-lines">
                                      <h5>{productData?.item_name}</h5>
                                    </div>
                                    <div className="product-price">
                                      <h3>₹ {productData?.price}</h3>
                                      <>
                                        <span className="price-note"> *Prices are subject to change. </span>
                                        <HtmlTooltip
                                          title={
                                            <>
                                              <span>Any price change will only be made by the retailer, never by QuickRegistries.com</span>
                                            </>
                                          }
                                          placement={isPortrait ? "bottom" : "right"}
                                          interactive
                                        >
                                          <InfoIcon src="assets/images/icon/info-icon.svg" alt="info-icon" className="info-icon" />
                                        </HtmlTooltip>
                                      </>
                                      <div className="view-visitor-product-details mt-3">
                                          {productData?.item_details?.size && (
                                            <p className="product-detail">
                                              <strong>Size:</strong> <span className="highlight-text">{productData.item_details.size}</span>
                                            </p>
                                          )}
                                          {productData?.item_details?.color && (
                                            <p className="product-detail">
                                              <strong>Color:</strong> <span className="highlight-text">{productData.item_details.color}</span>
                                            </p>
                                          )}
                                      </div>
                                      <div className="view-visitor-product-details">
                                          {productData?.item_details?.note && (
                                            <p className="product-detail">
                                              <strong>Notes:</strong> <span className="highlight-text">{productData.item_details.note}</span>
                                            </p>
                                          )}
                                      </div>
                                    </div>

                                    <div className="user-address mt-3">
                                      <div className="user-address-left">
                                        <i className="la la-shipping-fast"></i>
                                      </div>
                                      <div className="user-address-right">
                                        <p>
                                          Please review the shipping address before proceeding to checkout.
                                        </p>
                                      </div>
                                    </div>

                                    {shippingData ? (
                                    <>
                                      <div className="user-address-shipping-info">
                                        <p className="user-address-details">
                                          {shippingData.first_name}&nbsp;
                                          {shippingData.last_name}
                                          <br />
                                          {shippingData.address_1}
                                          {shippingData.address_2 && `, ${shippingData.address_2}`}
                                          <br />
                                          {shippingData.city},&nbsp;
                                          {shippingData.state},&nbsp;
                                          {shippingData.zip_code}
                                          <br />
                                          {shippingData.country}
                                        </p>
                                        <div className="mt-3 mb-4">
                                        {shippingData.instructions && (
                                          <>
                                            <span className="change-name-color">See shipping instructions: </span>
                                            <HtmlTooltips
                                              title={
                                                <>
                                                  <span>{shippingData.instructions}</span>
                                                </>
                                              }
                                              placement={isPortrait ? "bottom" : "right"}
                                              interactive
                                            >
                                              <InfoIcon src="assets/images/icon/info-icon.svg" alt="info-icon" className="info-icon" />
                                            </HtmlTooltips>
                                          </>
                                        )}
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <div className="prod-verify-msg mt-5 mb-4">
                                        <p>Please verify yourself to view the shipping address.
                                        <br />
                                        <Link to="#" className="change-name-color" onClick={(e) => handleVisitorVerification(e)}>CLICK HERE </Link> 
                                        to verify now.</p>
                                      </div>
                                    )}

                                    {productData.purchased_qty >= productData.required_qty ? (
                                      <div className="already-purchased-message">
                                        <p><i className="la la-check-circle"></i> This item has already been marked as gifted.</p>
                                      </div>
                                    ) : (
                                      <>
                                        <button
                                          type="button"
                                          className="form-btn view-product-btn"
                                          data-bs-toggle="modal"
                                          data-bs-target="#buy-product"
                                        >
                                        {productData && productData?.platform_name ? (
                                          `BUY THIS GIFT AT ${productData?.platform_name?.toUpperCase()}`
                                        ) : (
                                          'BUY THIS GIFT'
                                        )}
                                        </button>

                                        <div className="button-already button-bought primary-button">
                                          <button type="button" onClick={handleAlreadyBought} className="text-decoration-none">
                                            I bought this gift already
                                          </button>
                                          <span className="pipe change-color"> &nbsp; | &nbsp; </span>
                                          <button type="button" onClick={handleMultipleGifts} className="text-decoration-none">
                                            Buy multiple gifts<span><i className="la la-angle-right"></i></span>
                                          </button>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}
          </article>
         </main>
        <BuyGiftModal productData={productData} />
        <VerificationModal 
          fetchShippingInfo={fetchShippingInfo}
          myPublicKey={myPublicKey}
        />
        <BuyMultipleGiftsModal productData={productData} />
        <Footer />
      </div>
    )}
    </div>
  );
};

export default ViewProductPage;