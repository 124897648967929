import React from "react";
import { Link } from "react-router-dom";
import { showToast } from "../../utils/toastUtils";

const GuideSection = () => {

  const handleClick = () => {
    showToast('Something new is coming!');
  }

  return (
    <main className="main">
      <article>
        <section id="baby-guides" className="our-guide py-4">
          <div className="container">
            <div className="row">
              <div className="spacing-small"></div>

              <div className="col-md-4">
                <div
                  className="store-box"
                  data-aos="fade-right"
                  data-aos-delay="100"
                >
                  <h1 className="my-4 heading-med">Our Guides</h1>
                  <p className="mb-5">
                    Explore our curated insights and get expert advice for every step of your parenting journey. From
                    setting up the perfect nursery to choosing the right items for your registry, our comprehensive guides
                    are here to help. Stay informed and inspired as you navigate the beautiful path of parenthood. 
                    </p>
                  <div className="button">
                    <Link to="#" className="button-active" onClick={handleClick}>
                      Discover More
                    </Link>
                  </div>
                </div>
              </div>

              <div
                className="col-md-8"
                data-aos="fade-left"
                data-aos-delay="100"
              >
                <div className="blog-box">
                  <div className="spacing-small"></div>

                  <div className="row">
                    <div className="col-md-6 col-sm-6">
                      <div className="post">
                        <Link to="why-gift-registry-is-better">
                          <div className="post-img">
                            <img src="assets/images/blog/blog1.webp" alt="" />
                          </div>

                          <div className="post-date pt-3 pb-2">
                            <h6>Feb 07, 2025</h6>
                          </div>

                          <div className="post-heading">
                            <p>
                              Why Creating a Gift Registry is Better Than Buying Gifts Directly
                            </p>
                          </div>
                        </Link>
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-6">
                      <div className="post">
                        <Link to="why-parents-need-baby-registry">
                          <div className="post-img">
                            <img src="assets/images/blog/blog2.jpeg" alt="" />
                          </div>

                          <div className="post-date pt-3 pb-2">
                            <h6>Feb 07, 2025</h6>
                          </div>

                          <div className="post-heading">
                            <p>
                              Why Every Parent in India Needs a Baby Registry with Quick Registries
                            </p>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="spacing-mid"></div>
            </div>
          </div>
        </section>
      </article>
    </main>
  );
};

export default GuideSection;
