import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const PlatformRedirect = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const platform = navigator.userAgent.toLowerCase();
    const isAndroid = platform.includes('android');
    const isIOS = platform.includes('iphone') || platform.includes('ipad');
    const fromMobileApp = localStorage.getItem("fromMobileApp");
    if (window.location.pathname === "/start" && !fromMobileApp) {
      if (isAndroid) {
        window.location.href = process.env.REACT_APP_PLAY_STORE_URL;
      } else if (isIOS) {
        window.location.href = process.env.REACT_APP_APP_STORE_URL;
      } else {
        navigate('/');
      }
    }else{
      navigate('/');
    }
  }, [navigate]);

  return null;
};

export default PlatformRedirect;