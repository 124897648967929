/* global $ */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { showToast } from "../../utils/toastUtils";
import Header from "../../components/common/Header";
import Navbar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";
import { getRegistryCredentials } from '../../utils/registryUtils';
import api from "../../services/api";

const VisitorSetupPage = () => {
  const [loading, setLoading] = useState(true);
  const [passwordloading, setPasswordLoading] = useState(false);
  const [guestBookToggle, setGuestBookToggle] = useState(false);
  const [visitorPasswordToggle, setVisitorPasswordToggle] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [isNewPasswordValid, setIsNewPasswordValid] = useState(true);
  const [isVisitorPasswordModal, setIsVisitorPasswordModal] =  useState(false);
  const { accessToken, userId, registryId } = getRegistryCredentials();
  const [visitorDetails, setVisitorDetails] = useState({
    password_option: false,
    visitor_password: null,
    diary_visibility: false,
  });

  const fetchVisitorData = async () => {
    try {
      const details = await api.getVisitorDetails(accessToken, registryId);
      setVisitorDetails(details);
      setGuestBookToggle(details.diary_visibility);
      setVisitorPasswordToggle(details.password_option);
      setIsVisitorPasswordModal(details.password_option);
      setLoading(false); 
    } catch (error) {
      console.error('Error fetching visitor details:', error);
      setLoading(true); 
    }
  };

  useEffect(() => {
    fetchVisitorData();
    // eslint-disable-next-line
  }, []);

  const handleVisitorSorting = () => {};

  const handleGuestBook = async () => {
    try {
      const updatedGuestBook = !guestBookToggle;
      setGuestBookToggle(updatedGuestBook);
      await api.setGuestbookVisibility(accessToken, parseInt(userId), "dairy_entry_visibility", updatedGuestBook.toString());
      showToast(`Guestbook ${updatedGuestBook ? 'enabled' : 'disabled'} successfully!`, "success");
    } catch (error) {
      console.error('Error updating guestbook visibility:', error);
      showToast("Failed to update guestbook visibility", "error");
      setGuestBookToggle(!guestBookToggle);
    }
  };

  const handleVisitorPassword = async () => {
    try {
      if (visitorPasswordToggle) {
        const setPasswordData = {
          password_option: false,
        };
  
        await api.setVisitorPassword(registryId, accessToken, setPasswordData);
        setVisitorPasswordToggle(false);
        setIsVisitorPasswordModal(false);
        fetchVisitorData();
        showToast("Visitor password disabled successfully!");
      } else {
        setVisitorPasswordToggle(true);
        $("#visitor-password").modal("show");
      }
    } catch (error) {
      console.error("Error disabling visitor password:", error);
      showToast("Failed to disabled visitor password!");
    }
  };
  
  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handlePasswordChange = (e) => {
    setNewPassword(e.target.value);
    setIsNewPasswordValid(true);
  };

  const handleSaveVisitorPassword = async (e) => {
    setPasswordLoading(true)
    e.preventDefault();
    if (newPassword.trim() === "") {
      showToast("Please enter a password");
      setIsNewPasswordValid(false);
      return;
    }
    try {
        const setPasswordData = {
          visitor_password: newPassword,
        };
        await api.setVisitorPassword(registryId, accessToken, setPasswordData);

        $("#visitor-password").modal("hide");
        fetchVisitorData();
        setNewPassword('');
        setPasswordLoading(false);
        showToast(`Visitor password ${isVisitorPasswordModal ? 'updated' : 'saved'} successfully!`, "success");
    } catch (error) {
        console.error("Error visitor password:", error);
        showToast(`Failed to ${isVisitorPasswordModal ? 'update' : 'save'} visitor password`, "error");
      }
  };

  const handleCreateVisitorCancel = () => {
    $("#visitor-password").modal("hide");
    setVisitorPasswordToggle(false);
    setNewPassword('');
    setIsNewPasswordValid(true);
  };

  const handleEditVisitorCancel = () => {
    $("#visitor-password").modal("hide");
    setNewPassword('');
    setIsNewPasswordValid(true);
  };

  const handleEditPassword = () => {
    $("#visitor-password").modal("show");
  };

  return (
    <div className="wrapper animsition">
      <div className="wrapper-inner">
        <header className="header">
          <Header />
          <Navbar />
        </header>
        {loading ? (
            <div className="custom-loader-container main-loader fs-5">
              <div className="spinner-border custom-loader" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
        ) : (
        <main className="main main-dashboard">
          <article>
            <section>
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="dashboard">
                      <div className="user-dashboard text-center">
                        <div className="visitor-heading mt-3 mb-4">
                          <h3>Visitor Setup</h3>
                          <p className="custom-justify mt-4 fs-6">
                            This is where you control information for the guests that visit your registry. To customize
                            your visitor view, such as changing your background or the message to your guests.
                          </p>
                        </div>
                        <div className="gift-list my-5">
                          <div className="view-buttons">
                            <h4>Visitor Sorting</h4>
                            <div className="mt-4 fs-6">
                              <p>
                                SORT BY :
                                <span className="change-color"> RECENTLY ADDED </span>
                                <Link to="#">
                                  <i
                                    className="la la-pen edit-icon fs-5 custom-disabled"
                                    onClick={handleVisitorSorting}
                                  ></i>
                                </Link>
                              </p>
                              <p className="custom-justify">
                                This is the order of how your gifts will appear to guests that view your registry.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="gift-list my-5">
                          <div className="view-buttons">
                            <h4>GuestBook</h4>
                            <div className="toggle-buttons">
                              <div className="toggle-check-box">
                                <input
                                  type="checkbox"
                                  checked={guestBookToggle}
                                  onChange={handleGuestBook}
                                />
                              </div>
                            </div>
                            <div className={`mt-4 fs-6 ${!guestBookToggle ? 'custom-disabled' : ''}`}>
                              <p className="custom-justify">
                                When this option is on, your registry visitors can leave their name and a note for you. (When this
                                option is turned off, QuickRegistries.com will be unable to retain information on who visited your
                                registry.) <Link to="/guestbook" className="change-color" onClick={() => window.scrollTo(0, 0)}>View Guestbook</Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="gift-list my-5">
                          <div className="view-buttons">
                            <h4 className="visitor-password">Visitor Password</h4>
                            <div className="toggle-buttons">
                              <div className="toggle-check-box">
                                <input
                                  type="checkbox"
                                  checked={visitorPasswordToggle}
                                  onChange={handleVisitorPassword}
                                />
                              </div>
                            </div>
                            <div className={`mt-4 fs-6 ${!visitorPasswordToggle ? 'custom-disabled' : ''}`}>
                              <p>
                                YOUR PASSWORD :
                                <span className="change-color"> {visitorDetails.visitor_password} </span>
                                <Link to="#">
                                  <i
                                    className="la la-pen edit-icon fs-5"
                                    onClick={handleEditPassword}
                                  ></i>
                                </Link>
                              </p>
                              <p className="custom-justify">
                                When this option is enabled, only visitors with your visitor password can view your registry.
                                Please note that the visitor password is stored in plain text, as it is intended to be easily
                                shared with friends & family when needed.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal fade" id="visitor-password" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog common-popup">
                  <div className="modal-content">
                    <div className="modal-header text-center">
                      <p>{isVisitorPasswordModal ? "Visitor Password" : "Create a Visitor Password"}</p>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={isVisitorPasswordModal ? handleEditVisitorCancel : handleCreateVisitorCancel}></button>
                    </div>
                    <div className={`modal-body ${isVisitorPasswordModal ? 'mt-2' : 'mt-4'}`}>
                      {isVisitorPasswordModal ? <p className="fs-6 mb-3 text-center">Your Current Password: <span className="change-color">{visitorDetails.visitor_password} </span></p> : null }
                      <div className="user-box">
                        <input
                          name="visitor-password"
                          type={showNewPassword ? "text" : "password"}
                          value={newPassword}
                          maxLength={20}
                          className={`form-control ${isNewPasswordValid ? '' : 'is-invalid'}`}
                          onKeyPress={(e) => e.key === 'Enter' && handleSaveVisitorPassword(e)}
                          onChange={handlePasswordChange}
                          required
                        />
                        <p className="password-char-visitor">
                          Maximum 20 characters
                        </p>
                        <label>{isVisitorPasswordModal ? "New Password" : "Visitor Password"}</label>
                          <Link
                            className="password-toggle-icon position-absolute end-0 bottom-50 me-3"
                            onClick={toggleNewPasswordVisibility}
                          >
                            {showNewPassword ? (
                              <i className="las la-eye-slash fs-4"></i>
                            ) : (
                              <i className="las la-eye fs-4"></i>
                            )}
                          </Link>
                      </div>
                    </div>
                    <div className="user-detials-btn text-center mb-4 mt-3">
                    <button
                      type="button"
                      className="common-btn"
                      onClick={isVisitorPasswordModal ? handleEditVisitorCancel : handleCreateVisitorCancel}
                    >
                      CANCEL
                    </button>
                    <button
                      type="button"
                      className="btn common-btn active-btn"
                      onClick={handleSaveVisitorPassword}
                      disabled={passwordloading}
                    >
                      {passwordloading ? "SAVING..." : "SAVE"}
                    </button>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </article>
        </main>
        )}
        <Footer />
      </div>
    </div>
  );
};

export default VisitorSetupPage;
