import React, { useEffect } from "react";
import { Network } from "@capacitor/network";
import { showToast } from "../../../utils/toastUtils";

const NoConnectionModal = ({ setShowModal }) => {
  const handleRefresh = async () => {
    const status = await Network.getStatus();
    if (status.connected) {
      setShowModal(false);
      document.body.classList.remove("modal-connection-open");
    } else {
      showToast(<><i className="la la-warning i-icon"></i>&nbsp;No internet connection!</>);
    }
  };

  useEffect(() => {
    document.body.classList.add("modal-connection-open");
    return () => {
      document.body.classList.remove("modal-connection-open");
    };
  }, []);

  return (
    <>
      <div className="modal-connection-backdrop fade show"></div>
      <div className="modal modal-connection fade show" id="no-internet-connection" tabIndex="-1" aria-labelledby="noInternetModalLabel" aria-hidden="true" style={{ display: "block" }}>
        <div className="modal-dialog common-popup shop-popup">
          <div className="modal-content">
            <div className="modal-body text-center">
              <p className="mb-4">It seems you're offline. Please check your internet connection.</p>
              <div className="user-detials-btn text-center">
                <button className="common-modal-btn common-btn active-btn" onClick={handleRefresh}>Refresh</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NoConnectionModal;