import React, { useEffect, useState } from 'react';
import { Capacitor } from '@capacitor/core';

const AppInstallBanner = ({ isVisible, onClose }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [isNativeApp, setIsNativeApp] = useState(false);

  useEffect(() => {
    setIsNativeApp(Capacitor.isNativePlatform());

    const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
    setIsMobile(isMobileDevice);
  }, []);

  if (!isVisible || !isMobile || isNativeApp) {
    return null;
  }

  const handleInstallClick = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href = process.env.REACT_APP_APP_STORE_URL;
    } 
    else {
      window.location.href = process.env.REACT_APP_PLAY_STORE_URL;
    }
  };

  return (
    <div className="banner">
      <button onClick={onClose} className="close-banner-button"> &times; </button>

      <div className="logo-message-container">
        <img src="/assets/images/icon/icon.png" alt="Quick Registries Logo" className="banner-logo" />
        <p style={{ margin: 0 }}>Install our app for a better experience!</p>
      </div>

      <div className="user-detials-btn banner-btn">
        <button onClick={handleInstallClick} className="common-modal-btn common-btn install-button"> Install App </button>
      </div>
    </div>
  );
};

export default AppInstallBanner;