/* global $ */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { showToast } from "../../../utils/toastUtils";
import { getRegistryCredentials } from '../../../utils/registryUtils';
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import api from "../../../services/api";

const LifafaTab = ({ settingDetails, fetchSettingDetails }) => {
  const [upiId, setUpiId] = useState('');
  const [loading, setLoading] = useState(false);
  const [lifafaId, setLifafaId] = useState('');
  const [qrCode, setQrCode] = useState(null);
  const [isUpiIdValid, setIsUpiIdValid] = useState(true);
  const [isQrUploaded, setIsQrUploaded] = useState(false);
  const [isEditingUpiDetails, setIsEditingUpiDetails] = useState(false);
  const { accessToken, registryId, fromiOSDevice } = getRegistryCredentials();

  const handleUpiDetailsModal = () => {
    $("#upi-details").modal("show");
  };

  const handleSaveUpiDetails = async () => {
    const vpaPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9]+$/;

    if (upiId.trim() === '') {
      setIsUpiIdValid(false);
      showToast("Please enter UPI ID");
      return;
    }

    if (!vpaPattern.test(upiId)) {
      setIsUpiIdValid(false);
      showToast("Please enter a valid UPI ID");
      return;
    }

    if (!qrCode) {
      showToast("Please upload a QR code");
      return;
    }

    setLoading(true);
    
    try {
      if (isEditingUpiDetails) {
        let updatedQrCode = qrCode;

        if (qrCode.startsWith('http')) {
        updatedQrCode = null;
        }
        await api.updateLifafa(accessToken, registryId, lifafaId, upiId, updatedQrCode);
        showToast("UPI details updated successfully", "success");
      } else {
        await api.createLifafa(accessToken, registryId, upiId, qrCode);
        showToast("UPI details uploaded successfully", "success");
      }
      setIsUpiIdValid(true);
      setUpiId('');
      setQrCode(null);
      setIsQrUploaded(false);
      fetchSettingDetails();
      $("#upi-details").modal("hide");
    } catch (error) {
      console.error("Error saving lifafa details:", error);
      showToast("Error saving lifafa details", "error");
    } finally {
      setLoading(false);
    }
  };

  const handleCancelUpiDetails = () => {
    setUpiId('');
    setQrCode(null);
    setIsUpiIdValid(true);
    setIsQrUploaded(false);
    $("#upi-details").modal("hide");
  };

  const handleUpiIdChange = (e) => {
    setUpiId(e.target.value);
    if (e.target.value.trim() !== '') {
      setIsUpiIdValid(true);
    }
  };

  const handleiOSUpload = async () => {
    try {
      const photo = await Camera.getPhoto({
        resultType: CameraResultType.DataUrl,
        source: CameraSource.Photos,
        quality: 100
      });
  
      const imageUrl = photo.dataUrl;
      setQrCode(imageUrl);
      setIsQrUploaded(true);
    } catch (error) {
      console.error('Error selecting photo', error);
    }
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type.startsWith('image')) {
        const reader = new FileReader();
        reader.onload = (event) => {
          setQrCode(event.target.result);
          setIsQrUploaded(true);
        };
        reader.readAsDataURL(file);
      } else {
        showToast("Please upload a valid image file (e.g., PNG, JPEG, JPG)");
      }
    }
  };

  const handleEditUpiDetails = () => {
    if (settingDetails && settingDetails.lifafa_details) {
      const qrCodeValue = settingDetails.lifafa_details.qr_code;
      const lifafaId = settingDetails.lifafa_details.id;
      setQrCode(qrCodeValue);
      setLifafaId(lifafaId);
      setIsEditingUpiDetails(true);
      $("#upi-details").modal("show");
    }
  };

  const handleDeleteUpiDetails = () => {
    if (settingDetails && settingDetails.lifafa_details) {
      const lifafaId = settingDetails.lifafa_details.id;
      setLifafaId(lifafaId);
    }
    $("#delete-upi-modal").modal("show");
  };

  const handleConfirmDeleteUpiDetails = async () => {
    try {
      await api.deleteLifafaDetails(accessToken, lifafaId);
      showToast("Lifafa details deleted successfully", "success");
      setUpiId('');
      setQrCode(null);
      setIsQrUploaded(false);
      fetchSettingDetails();
      setIsEditingUpiDetails(false);
      $("#delete-upi-modal").modal("hide");
    } catch (error) {
      console.error("Error deleting lifafa details:", error);
      showToast("Failed to delete lifafa details", "error");
    }
  };

  const handleCancelDeleteUpiDetails = () => {
    $("#delete-upi-modal").modal("hide");
  };

  return (
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingTwo"> 
        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
          LIFAFA SETTINGS
        </button>
      </h2>
      <div
        className="accordion-collapse collapse"
        id="collapseTwo"
        aria-labelledby="headingTwo"
        data-bs-parent="#accordion1"
      >
        <div className="accordion-body">
          <div className="row">
            <div className="col-md-12">
              <div className="view-mode">
                <div className="view-heading adjust-heading">
                  <h3>{settingDetails?.lifafa_details ? 'Your UPI ID Address is:' : 'Connect your UPI Account'}</h3>
                  {!settingDetails?.lifafa_details ? (
                    <div className="social-btn btn-fix adjust-lifafa">
                      <Link className="google-btn upi" onClick={handleUpiDetailsModal}>
                        Upload UPI Details
                      </Link>
                    </div>
                    ) : (
                    <div>
                      <span className="fs-6"><u>{settingDetails?.lifafa_details.upi_id}</u></span>
                      <Link to="#" className="change-color mx-2" onClick={handleEditUpiDetails}><i className="la la-pen fs-5"></i></Link>
                      <Link to="#" className="change-color" onClick={handleDeleteUpiDetails}><i className="la la-trash fs-5"></i></Link>
                    </div>
                  )}
                </div>
                <div className="user-details">
                  <div className="user-settings">
                    <div className="note">
                      <p>
                        By uploading your UPI details, you enable the Lifafa feature in your registry. This allows buyers to send 
                        Lifafa using your UPI details, making it easier and more convenient for them to contribute gifts. Ensure 
                        your UPI Details is correct and active to receive Lifafa seamlessly.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="upi-details" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog common-popup">
            <div className="modal-content">
              <div className="modal-header text-center">
                <p>{isEditingUpiDetails ? "Update UPI Details" : "Set UPI Details"}</p>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className={`modal-body ${isEditingUpiDetails ? 'mt-2' : 'mt-4'}`}>
                {isEditingUpiDetails ? <p className="fs-6 mb-3 text-center">Your Current UPI ID: <span className="change-color">{settingDetails?.lifafa_details.upi_id}</span></p> : null }
                <div className="user-box">
                  <input
                    name="upi-id"
                    value={upiId}
                    className={`form-control ${isUpiIdValid ? '' : 'is-invalid'}`}
                    onKeyPress={(e) => e.key === 'Enter' && handleSaveUpiDetails(e)}
                    onChange={handleUpiIdChange}
                    required
                  />
                  <label>{settingDetails?.lifafa_details ? 'Enter New UPI ID' : 'Enter Your UPI ID'}</label>
                </div>
                <div className="upload-button text-center">
                  <label
                    htmlFor="file-upload"
                    className="custom-upload-btn"
                    onClick={fromiOSDevice ? handleiOSUpload : null}
                  >
                    <i className="las la-cloud-upload-alt fs-5"></i> {settingDetails?.lifafa_details || isQrUploaded ? 'Change QR' : 'Upload QR'}
                  </label>
                  {!fromiOSDevice && (
                    <input
                      type="file"
                      id="file-upload"
                      onChange={handleFileInputChange}
                      style={{ display: 'none' }}
                      accept="image/png, image/jpeg, image/jpg"
                    />
                  )}
                  {qrCode && (
                    <div className="qr-preview mt-2">
                      <img src={qrCode} alt="Uploaded QR" />
                    </div>
                  )}
                </div>
              </div>
              <div className="user-detials-btn text-center mb-4 mt-3">
                <button
                  type="button"
                  className="btn common-btn"
                  onClick={handleCancelUpiDetails}
                >
                  CANCEL
                </button>
                <button
                  type="button"
                  className="btn common-btn active-btn"
                  onClick={handleSaveUpiDetails}
                  disabled={loading}
                >
                {loading ? (
                  <>
                    {isEditingUpiDetails ? 'Updating...' : 'Saving...'}
                  </>
                ) : (
                  isEditingUpiDetails ? 'UPDATE' : 'SAVE'
                )}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="delete-upi-modal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body row">
                <div className="col-lg-12">
                  <div className="confirmation-modal text-center mt-4">
                    <h5 className="mb-4">Delete UPI Details</h5>
                    <p>Are you sure you want to delete your UPI details?</p>
                  </div>
                  <div className="user-detials-btn text-center mt-4 mb-4">
                    <button type="button" className="common-modal-btn common-btn" onClick={handleCancelDeleteUpiDetails}>NO</button>
                    <button type="button" className="common-modal-btn common-btn active-btn" onClick={handleConfirmDeleteUpiDetails}>YES</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default LifafaTab;
