import { useEffect } from "react";
import { App } from "@capacitor/app";
import { useNavigate } from "react-router-dom";
import { getRegistryCredentials } from "../../utils/registryUtils";

const AppUrlListener = () => {
  const navigate = useNavigate();
  const { accessToken, fromMobileApp, fromiOSDevice } = getRegistryCredentials();

  useEffect(() => {
    App.addListener("appUrlOpen", (event) => {
      // Android Code
      if (fromMobileApp && !fromiOSDevice) {
        const deepLink = event.url;

        // Validate URL
        // if (!deepLink || typeof deepLink !== "string") {
        //   console.error("Invalid URL received:", event.url);
        //   return;
        // }

        // Helper function to extract domain
        const getDomainFromUrl = (url) => {
          if (url.includes("quickregistries.com")) return "quickregistries.com";
          if (url.includes("quickregistry.in")) return "quickregistry.in";
          return null;
        };

        // Main URL handling logic
        switch (true) {
          // Handle Share URLs
          case deepLink.startsWith("in.quickregistry.app://"):
            localStorage.setItem("trimmedUrl", deepLink);
            navigate("/add-gift-modal");
            break;

          // Handle Web Domain URLs
          case deepLink.includes("quickregistries.com") || deepLink.includes("quickregistry.in"):
            const domain = getDomainFromUrl(deepLink);
            const appLink = deepLink.split(domain)[1]?.replace(/^\/+|\/+$/g, "") || "/";

            // If the link is just the domain, check for accessToken
            if (appLink === "/") {
              navigate(accessToken ? "/dashboard" : "/m-homepage");
            } else {
              // Navigate to the path extracted from the URL
              navigate(appLink);
            }
            break;

          // Handle Invalid URLs
          default:
            console.error("Invalid domain in URL:", event.url);
        }
      }

      // iOS Code
      if (fromiOSDevice) {
        const deepLink = event.url.split("share_url=").pop();
        if (deepLink) {
        const normalizedUrl = deepLink.replace(/^https?:\/\/(www\.)?/, "");

        // Check if the URL belongs to the valid domains
        if (
          normalizedUrl.startsWith("quickregistries.com/") ||
          normalizedUrl.startsWith("quickregistry.in/") ||
          normalizedUrl === "quickregistries.com" ||
          normalizedUrl === "quickregistry.in"
        ) {
          const path = normalizedUrl.split("/").slice(1).join("/"); 
          if (path) {
            navigate(`/${path}`);
          } else {
            navigate(accessToken ? "/dashboard" : "/m-homepage");
          }
        } else {
          // For any other domains or text, navigate to add-gift-modal
          localStorage.setItem("trimmedUrl", deepLink);
          navigate("/add-gift-modal");
        }
      }
    }
    });

    return () => {
      App.removeAllListeners();
    };
    // eslint-disable-next-line
  }, [navigate]);

  return null;
};

export default AppUrlListener;