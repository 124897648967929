import React, { useState} from 'react';
import { Link } from "react-router-dom";

const NotificationsTab = () => {
    const [emailCheckboxes, setEmailCheckboxes] = useState({
        featureCheckbox: false,
        dealsCheckbox: false,
        giveawaysCheckbox: false,
        unsubscribeAllCheckbox: false,
    });

    const [purchasesCheckboxes, setPurchasesCheckboxes] = useState({
        hidePurchased: false,
        emailNotify: false,
        textNotify: false,
    });

    const [searchCheckboxes, setSearchCheckboxes] = useState({
      allowOnMyRegistry: false,
      allowOnSearchEngines: false,
    });  
    
    const [locationLanguageCurrency, setLocationLanguageCurrency] = useState({
      selectedCountry: 'International',
      selectedLanguage: 'English',
      setDefaultCurrency: false,
      selectedCurrency: 'INR',
  });
    
    const handleUnsubscribeAllChange = () => {
        setEmailCheckboxes((prevCheckboxes) => ({
          ...prevCheckboxes,
          featureCheckbox: false,
          dealsCheckbox: false,
          giveawaysCheckbox: false,
          unsubscribeAllCheckbox: !prevCheckboxes.unsubscribeAllCheckbox,
        }));
    };

    const handleEmailCheckboxChange = (checkboxName) => {
      setEmailCheckboxes((prevCheckboxes) => ({
        ...prevCheckboxes,
        [checkboxName]: !prevCheckboxes[checkboxName],
        unsubscribeAllCheckbox: false,
      }));
    };

    const handleCheckboxChange = (checkboxName) => {
      setPurchasesCheckboxes((prevCheckboxes) => {
        let updatedCheckboxes = { ...prevCheckboxes };
  
        if (checkboxName === 'hidePurchased') {
          updatedCheckboxes.hidePurchased = !prevCheckboxes.hidePurchased;
          if (updatedCheckboxes.hidePurchased) {
            updatedCheckboxes.emailNotify = false;
            updatedCheckboxes.textNotify = false;
          }
        } else if (checkboxName === 'emailNotify') {
          updatedCheckboxes.emailNotify = !prevCheckboxes.emailNotify;
          if (updatedCheckboxes.emailNotify) {
            updatedCheckboxes.hidePurchased = false;
          }
        } else if (checkboxName === 'textNotify') {
          updatedCheckboxes.textNotify = !prevCheckboxes.textNotify;
          if (updatedCheckboxes.textNotify) {
            updatedCheckboxes.hidePurchased = false;
          }
        }
  
        return updatedCheckboxes;
      });
    };

    const handleSearchCheckboxChange = (checkboxName) => {
      setSearchCheckboxes((prevCheckboxes) => ({
          ...prevCheckboxes,
          [checkboxName]: !prevCheckboxes[checkboxName],
      }));
    };

    const handleLocationLanguageChange = (field, value) => {
      setLocationLanguageCurrency((prevDetails) => ({
          ...prevDetails,
          [field]: value,
      }));
    };

    const handleDefaultCurrencyCheckboxChange = () => {
      setLocationLanguageCurrency((prevDetails) => ({
          ...prevDetails,
          setDefaultCurrency: !prevDetails.setDefaultCurrency,
      }));
  };

  return (
    <div className="accordion-item">
    <h2 className="accordion-header" id="headingThree">
        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
            NOTIFICATIONS & OPTIONS
        </button>
    </h2>
    <div className="accordion-collapse collapse" id="collapseThree" aria-labelledby="headingThree" data-bs-parent="#accordion1">
        <div className="accordion-body">
        <div className="row">
            <div className="col-md-6">
            <div className="view-mode">
                <div className="view-heading">
                <h3>Notifications</h3>
                </div>
                <div className="user-details custom-disabled">
                <div className="user-settings">
                    <div className="settings-label">PURCHASES</div>
                    <div className="settings-input-text">
                    <div className="checkbox">
                    <label className="checkbox-text">
                    <input
                        type="checkbox"
                        checked={purchasesCheckboxes.hidePurchased}
                        onChange={() => handleCheckboxChange('hidePurchased')}
                    />
                    Hide what’s been purchased
                    </label>
                    </div>
                    <div className="checkbox">
                    <label className="checkbox-text">
                    <input
                        type="checkbox"
                        checked={purchasesCheckboxes.emailNotify}
                        onChange={() => handleCheckboxChange('emailNotify')}
                    />
                    Email & notify me when gifts are bought
                    </label>
                    </div>
                    <div className="checkbox">
                    <label className="checkbox-text">
                    <input
                        type="checkbox"
                        checked={purchasesCheckboxes.textNotify}
                        onChange={() => handleCheckboxChange('textNotify')}
                    />
                    Text me when gifts are bought*
                    </label>
                        <div className="gifts-bought">
                        <div className="service-provider">
                            <label>Service Provider*</label>
                            <div className="select-wrapper">
                            <select name="Service" id="" className="form-control">
                                <option value="Select">Select</option>
                                <option value="Airtel">Airtel</option>
                                <option value="Jio">Jio</option>
                                <option value="VI">VI</option>
                            </select>
                            </div>
                        </div>
                        <div className="user-box">
                            <input type="text" name="" required value="+91 " />
                            <label>Mobile Number*</label>
                        </div>
                        <div className="user-detials-btn">
                            <button type="button" className="settings-btn">Cancel</button>
                            <button type="button" className="settings-btn active-btn">save</button>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="view-mode mt-5">
                <div className="view-heading">
                <h3>Email Communications</h3>
                </div>
                <div className="user-details custom-disabled">
                <div className="user-settings">
                <div className="settings-label">PURCHASES</div>
                <div className="settings-input-text">
                    <div className="checkbox">
                    <label className="checkbox-text">
                        <input
                        type="checkbox"
                        checked={emailCheckboxes.featureCheckbox}
                        onChange={() => handleEmailCheckboxChange('featureCheckbox')}
                        />
                        Our top features and insider tips on how to create the ultimate gift list.
                    </label>
                    </div>
                    <div className="checkbox">
                    <label className="checkbox-text">
                        <input
                        type="checkbox"
                        checked={emailCheckboxes.dealsCheckbox}
                        onChange={() => handleEmailCheckboxChange('dealsCheckbox')}
                        />
                        Don't-miss perks, deals, and promos from our retail partners.
                    </label>
                    </div>
                    <div className="checkbox">
                    <label className="checkbox-text">
                        <input
                        type="checkbox"
                        checked={emailCheckboxes.giveawaysCheckbox}
                        onChange={() => handleEmailCheckboxChange('giveawaysCheckbox')}
                        />
                        News about exciting sweepstakes and giveaways.
                    </label>
                    </div>

                    <div className="checkbox">
                    <label className="checkbox-text">
                        <input
                        type="checkbox"
                        checked={emailCheckboxes.unsubscribeAllCheckbox}
                        onChange={handleUnsubscribeAllChange}
                        />
                        Unsubscribe from all*
                    </label>
                    <div className="gifts-bought">
                        <div className="user-detials-btn">
                        <button type="button" className="settings-btn">Cancel</button>
                        <button type="button" className="settings-btn active-btn">save</button>
                        </div>
                    </div>
                    <div className="note">
                        <p>*You may still receive emails regarding important account activity.</p>
                    </div>
                    </div>
                </div>
                </div>
                </div>
            </div>
            </div>
            <div className="col-md-6">
            <div className="view-mode">
                <div className="view-heading">
                <h3>Search Options</h3>
                </div>
                <div className="user-details custom-disabled">
                <div className="user-settings">
                    <div className="settings-label">ENABLE SEARCH</div>
                    <div className="settings-input-text">
                    <div className="checkbox">
                        <label className="checkbox-text">
                        <input type="checkbox"
                        checked={searchCheckboxes.allowOnMyRegistry}
                        onChange={() => handleSearchCheckboxChange('allowOnMyRegistry')}
                        />
                        Allow my gift list to be found on QuickRegistries.com
                    </label>
                    </div>
                    <div className="checkbox">
                        <label className="checkbox-text">
                        <input type="checkbox"
                        checked={searchCheckboxes.allowOnSearchEngines}
                        onChange={() => handleSearchCheckboxChange('allowOnSearchEngines')}                                 
                        />
                        Allow my gift list to be found on search engines
                        </label>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="view-mode">
                <div className="view-heading">
                <h3>Location, Language, Currency</h3>
                </div>
                <div className="user-details custom-disabled">
                <div className="user-settings">
                    <div className="settings-input-text">
                    <div className="gifts-bought auto-select">
                        <div className="service-provider">
                        <label>SHOW CONTENT FOR THIS COUNTRY:</label>
                        <div className="select-wrapper">
                            <select name="Country" className="form-control" value={locationLanguageCurrency.selectedCountry} 
                            onChange={(e) => handleLocationLanguageChange('selectedCountry',e.target.value)}>     

                            <option value="International">International</option>
                            <option value="Canada">Canada</option>
                            <option value="India">India</option>
                            </select>
                        </div>
                    </div>
                    </div>
                    <div className="gifts-bought auto-select">
                        <div className="service-provider">
                        <label>PREFERRED LANGUAGE</label>
                        <div className="select-wrapper">
                            <select name="Language"  className="form-control" value={locationLanguageCurrency.selectedLanguage}
                            onChange={(e) => handleLocationLanguageChange('selectedLanguage',e.target.value)}> 

                            <option value="English">English</option>
                            <option value="Spanish">Spanish</option>
                            <option value="French">French</option>
                            </select>
                        </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    <div className="settings-label">CURRENCY</div>
                    <div className="settings-input-text">
                    <div className="checkbox">
                        <label className="checkbox-text currency">
                        <input type="checkbox"
                        checked={locationLanguageCurrency.setDefaultCurrency}
                        onChange={handleDefaultCurrencyCheckboxChange}  
                        />
                        Set default currency
                        <div className="select-wrapper mt-3">
                            <select name="Currency" className="form-control" value={locationLanguageCurrency.selectedCurrency}
                            onChange={(e) => handleLocationLanguageChange('selectedCurrency',e.target.value)}
                            disabled={!locationLanguageCurrency.setDefaultCurrency}>

                            <option value="INR">INR ₹</option>
                            <option value="USD">USD $</option>
                            <option value="EUR">EUR €</option>
                            <option value="GBP">GBP £</option>
                            </select>
                        </div>
                        </label>
                    </div>
                </div>
                </div>
            </div>
            <div className="view-mode mt-5">
                <div className="view-heading">
                {/* <h3>Connections</h3> */}
                </div>
                <div className="user-details user-box ">
                <div className="social-btn btn-fix mt-4 custom-disabled">
                    <Link to="" className="google-btn">
                    <i className="la la-google"></i> Connect to Google
                    </Link>
                    {/* <Link to="" className="fb-btn">
                    <i className="la la-facebook"></i> Connect with Facebook
                    </Link> */}
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
    </div>
    </div>
  );
};

export default NotificationsTab;
