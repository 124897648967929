import { useState, useEffect } from "react";
import { Network } from "@capacitor/network";

const useNetworkStatus = (setShowModal) => {
  const [isOffline, setIsOffline] = useState(false);
  const [hasStatusChecked, setHasStatusChecked] = useState(false);

  useEffect(() => {
    const checkNetworkStatus = async () => {
      const status = await Network.getStatus();
      setIsOffline(!status.connected);
      if (!status.connected) {
        setShowModal(true);
      }
    };
  
    const handleNetworkStatusChange = async (status) => {
      setIsOffline(!status.connected);
      if (status.connected && !hasStatusChecked) {
        setHasStatusChecked(true);
        setShowModal(false);
      } else if (!status.connected) {
        setShowModal(true);
        setHasStatusChecked(false);
      }
    };
  
    checkNetworkStatus();
  
    let listener;
  
    const addListener = async () => {
      listener = await Network.addListener("networkStatusChange", handleNetworkStatusChange);
    };
  
    addListener();
  
    return () => {
      if (listener && typeof listener.remove === "function") {
        listener.remove();
      }
    };
  }, [setShowModal, hasStatusChecked]);  

  return isOffline;
};

export default useNetworkStatus;